import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Container, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Loader from "_components/loader";
import { GET_USER_CONTACT_INFO, ADD_UPDATE_USER_CONTACT_INFO } from "_helpers/api";

function ContactInfoModal(props) {
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdateAction, setIsUpdateAction] = useState(false);
    const [selectedContactInfo, setSelectedContactInfo] = useState([]);
    const [allContactInfo, setAllContactInfo] = useState([]);
    const [contactInfoCurrentLabel, setContactInfoCurrentLabel] = useState("")
    const [contactInfoCurrentValue, setContactInfoCurrentValue] = useState("")
    const [currentPositionForContactInfo, setCurrentPositionForContactInfo] = useState(0)
    const { user: authUser } = useSelector((x) => x.auth);
    const { primary: primaryid } = useSelector((x) => x.auth);

    useEffect(() => {
        fetchSavedContactInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchSavedContactInfo = () => {
        setIsLoading(true)
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        let data = {
            fbUserId: props.data.fb_user_id,
            userId: authUser.id,
            loggedInFBId: primaryid
        };

        axios.post(GET_USER_CONTACT_INFO, data, { headers }).then((response) => {
            let contactInfos = JSON.parse(response.data.userContactInfo?.contact_info || '[]');
            setAllContactInfo(contactInfos);
            setIsLoading(false)
            setCurrentPositionForContactInfo(contactInfos.length)
        }).catch((exception) => {
            setAllContactInfo([])
            setIsLoading(false)
        })
    }

    const handleAddUpdateContactInfo = (e) => {
        e.preventDefault();
        if (contactInfoCurrentLabel.trim().length === 0) {
            toast.warning("Contact Info Label is required", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else if (contactInfoCurrentValue.trim().length === 0) {
            toast.warning("Contact Info Value is required", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setIsLoading(true)
            let tempContactInfo = [...allContactInfo];
            let slug = contactInfoCurrentLabel.toLowerCase().replace(/[^\w\s-]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-').trim();
            tempContactInfo[currentPositionForContactInfo] = {
                label: contactInfoCurrentLabel,
                slug: slug + '-' + currentPositionForContactInfo,
                value: contactInfoCurrentValue
            }
            let action = "save";
            if (isUpdateAction) {
                action = "update";
            }
            updateContactInfoJsonInDB(tempContactInfo, action)
        }
    }

    const updateContactInfoJsonInDB = (conactInfo = [], action = "save") => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        let data = {
            fbUserId: props.data.fb_user_id,
            userId: authUser.id,
            loggedInFBId: primaryid,
            contactsInfo: JSON.stringify(conactInfo)
        };
        axios.post(ADD_UPDATE_USER_CONTACT_INFO, data, { headers }).then((response) => {
            let contactInfos = JSON.parse(response.data.userContactInfo?.contact_info || '[]');
            setContactInfoCurrentLabel("")
            setContactInfoCurrentValue("")
            setAllContactInfo(contactInfos);
            setCurrentPositionForContactInfo(contactInfos.length)
            setIsUpdateAction(false)
            setIsLoading(false)
            setIsAllSelected(false)
            setSelectedContactInfo([])
            let message = "Conatct info saved successfully";
            if (action === "update") {
                message = "Conatct info updated successfully";
            } else if (action === "delete") {
                message = "Conatct info deleted successfully";
            }
            toast.success(message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }).catch((exception) => {
            setAllContactInfo([])
            setIsUpdateAction(false)
            setIsLoading(false)
            setIsAllSelected(false)
            setSelectedContactInfo([])
            toast.error(exception.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
    }

    const handleCheckboxChange = () => {
        let temp = [...selectedContactInfo];
        let temp2 = [...allContactInfo];
        if (temp.length === temp2.length) {
            temp = [];
        } else {
            temp = allContactInfo.reduce((tempSlugArr, item) => {
                tempSlugArr.push(item.slug)
                return tempSlugArr
            }, [])
        }
        setIsAllSelected(temp.length === temp2.length)
        setSelectedContactInfo(temp)
        setContactInfoCurrentLabel("")
        setContactInfoCurrentValue("")
        setCurrentPositionForContactInfo(allContactInfo.length)
    }
    const handleDeleteContactInfo = () => {
        let data = allContactInfo.reduce((reducedArr, item) => {
            if (selectedContactInfo.indexOf(item.slug) === -1) {
                reducedArr.push(item)
            }
            return reducedArr;
        }, []);
        if (selectedContactInfo.length === 0) {
            toast.warning("Select atleast one contact info to delete", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            Swal.fire({
                title: "Delete Contact Info",
                text: "Are you sure you want to delete selected Contact Info?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: '#572F5B',
                cancelButtonColor: '#D9D9D9',
                confirmButtonText: 'Yes, delete'
            }).then((result) => {
                if (result.isConfirmed) {
                    setIsLoading(true)
                    updateContactInfoJsonInDB(data, "delete")
                }
            })
        }
    }
    const showEditForm = () => {
        let selectedSlug = selectedContactInfo[0];
        let data = allContactInfo.find((item) => {
            return (item.slug === selectedSlug)
        });
        let indx = allContactInfo.findIndex((item) => {
            return (item.slug === selectedSlug)
        });
        setCurrentPositionForContactInfo(indx)
        setContactInfoCurrentLabel(data.label)
        setContactInfoCurrentValue(data.value)
        setIsUpdateAction(true);
    }

    const handleLabelChange = (e) => {
        setContactInfoCurrentLabel(e.target.value)
    }
    const handleValueChange = (e) => {
        setContactInfoCurrentValue(e.target.value)
    }

    const handleSingleCheckboxChange = (slug) => {
        setIsUpdateAction(false);
        let temp = [...selectedContactInfo];
        let indx = selectedContactInfo.indexOf(slug);
        if (indx > -1) {
            temp.splice(indx, 1);
        } else {
            temp.push(slug)
        }
        setSelectedContactInfo(temp)
        setIsAllSelected(temp.length === allContactInfo.length)
        setContactInfoCurrentLabel("")
        setContactInfoCurrentValue("")
        setCurrentPositionForContactInfo(allContactInfo.length)
    }

    return (
        <Modal className="contact-info-modal" show={props.show} onHide={props.handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Contact info for {props.data.fb_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col md={12}>
                            <Form onSubmit={handleAddUpdateContactInfo}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="infoLabel">
                                        <Form.Control maxLength={30} minLength={2} type="text" value={contactInfoCurrentLabel} placeholder="Label" onChange={handleLabelChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="infoValue">
                                        <Form.Control maxLength={130} minLength={2} type="text" value={contactInfoCurrentValue} placeholder="Value" onChange={handleValueChange} />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Button className="contact-info-submit-button w-100" type="submit" variant="primary">{
                                            isUpdateAction ? "Update" : "Add"
                                        }</Button>
                                    </Col>
                                </Row>
                            </Form>
                            <Row className="mx-0 mb-2">
                                <Col md={12} className="mt-5 border-bottom">
                                    {
                                        (!(allContactInfo.length === 0 || isLoading)) ?
                                            <Row className="mb-1">
                                                <Col md={10}>
                                                    <Form.Group id="formGridCheckbox">
                                                        <Form.Check id="notes-select-all" checked={isAllSelected} onChange={handleCheckboxChange} type="checkbox" label="Select All" />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={2} className="icon-container-col">
                                                    {
                                                        selectedContactInfo.length === 1 ? <BorderColorIcon style={{ marginRight: "10px" }} className="notes-edit-icon" onClick={() => { showEditForm() }} /> : <BorderColorIcon style={{ marginRight: "10px" }} className="notes-edit-icon disabled" />

                                                    }
                                                    {
                                                        (selectedContactInfo.length > 0 && !isUpdateAction) ? <DeleteIcon className="notes-delete-icon" onClick={() => { handleDeleteContactInfo() }} /> : <DeleteIcon className="notes-delete-icon disabled" />
                                                    }
                                                </Col>
                                            </Row> :
                                            <Row className="mb-1">&nbsp;</Row>
                                    }
                                </Col>
                            </Row>
                            <Row className="tags_sec" style={{ maxHeight: "220px", minHeight: "220px" }}>
                                <Col md={12}>
                                    {
                                        isLoading ? <Loader /> :
                                            allContactInfo.map((noteInfo, index) => {
                                                return (
                                                    <Row key={(new Date().getTime()) + `--${index}`} className={"mb-1 mx-0 notes-item " + (selectedContactInfo.includes(noteInfo.slug) ? "selected" : "")}>
                                                        <Col md={12}>
                                                            <Form.Group>
                                                                <Form.Check id={`notes-checkbox-${noteInfo.slug}`} checked={selectedContactInfo.includes(noteInfo.slug)} onChange={() => handleSingleCheckboxChange(noteInfo.slug)} type="checkbox" label={
                                                                    <div className="label-key-value-pair">
                                                                        <div className="label-key">{noteInfo.label}</div>
                                                                        <div className="label-value">
                                                                            {
                                                                                noteInfo.value.length > 25 ?
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        key={(new Date().getTime()) + `-key-${index}`}
                                                                                        overlay={
                                                                                            <Tooltip id={(new Date().getTime()) + `-id-${index}`}>{noteInfo.value}</Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <span>{noteInfo.value}</span>
                                                                                    </OverlayTrigger> :
                                                                                    <span>{noteInfo.value}</span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                } />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                    }
                                    {/**<div>{noteInfo.label} : {noteInfo.value}</div> */}
                                    {
                                        (allContactInfo.length === 0 && !isLoading) && <p className="no-data">You are yet to create any info for this contact</p>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default ContactInfoModal;
