import React, { useState } from "react";
import { useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { IMPORT_TAG } from "_helpers/api";
import { toast } from "react-toastify";
import axios from "axios";

export default function ImportTag(props) {
    const [show, setShow] = useState(false);
    const [csvFile, setCsvFile] = useState();
    const { user: authUser } = useSelector((x) => x.auth);
    const [errormsg, setErrorMsg] = useState();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleValue = (e) => {
        setErrorMsg("");
        setCsvFile(e.target.files[0]);
    };
    const importsavetag = () => {
        const headers = {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${authUser.token}`,
        };

        if(!csvFile || csvFile.type !== "text/csv") {
            setErrorMsg("Please select an CSV File.");
            return false;
        }

        const formData = new FormData();
        formData.append("file", csvFile);
        axios
            .post(IMPORT_TAG, formData, {
                headers: headers,
            })
            .then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    props.tagdata(response.data.tags);
                    setErrorMsg("");
                    handleClose();
                    setCsvFile();
                } else {
                    setErrorMsg(response.data.message);
                }
            })
            .catch((error) => { });
    };

    return (
        <>
            <div className="new_add_button-2" onClick={handleShow}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M4.16683 9.37631C4.51201 9.37631 4.79183 9.65613 4.79183 10.0013L4.79183 15.0013C4.79183 15.1164 4.8851 15.2096 5.00016 15.2096L15.0002 15.2096C15.1152 15.2096 15.2085 15.1164 15.2085 15.0013L15.2085 10.0013C15.2085 9.65613 15.4883 9.37631 15.8335 9.37631C16.1787 9.37631 16.4585 9.65613 16.4585 10.0013L16.4585 15.0013C16.4585 15.8067 15.8056 16.4596 15.0002 16.4596L5.00016 16.4596C4.19475 16.4596 3.54183 15.8067 3.54183 15.0013L3.54183 10.0013C3.54183 9.65613 3.82165 9.37631 4.16683 9.37631Z" fill="#572F5B" /><path d="M9.07081 13.011C8.61058 13.011 8.23748 12.6379 8.23748 12.1776L8.23748 8.13099C7.94129 8.11188 7.64534 8.08796 7.34974 8.05923L7.0954 8.03451C6.68187 7.99432 6.43421 7.55518 6.61378 7.1805C7.34379 5.65724 8.34277 4.27825 9.56266 3.10987L9.58873 3.08491C9.82112 2.86232 10.1876 2.86232 10.42 3.08491L10.4461 3.10987C11.666 4.27825 12.6649 5.65724 13.395 7.1805C13.5745 7.55518 13.3269 7.99432 12.9133 8.03451L12.659 8.05923C12.3634 8.08796 12.0674 8.11188 11.7712 8.13099L11.7712 12.1776C11.7712 12.6379 11.3981 13.011 10.9379 13.011L9.07081 13.011Z" fill="#572F5B" /></svg>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton style={{ border: 0 }}>
                    <Modal.Title>ADD File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form enctype="multipart/form-data">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Upload Csv File</Form.Label>
                            <Form.Control
                                type={"file"}
                                accept={".csv"}
                                className="originalClass"
                                placeholder="Enter Tag Name"
                                autoFocus
                                onChange={(e) => handleValue(e)}
                            />
                            {errormsg !== "" ? (
                                <h6 style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>{errormsg}</h6>
                            ) : (
                                ""
                            )}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{ border: 0 }}>
                    <div
                        className="add_tag button_global"
                        onClick={(e) => importsavetag(e)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M9.90359 4.80688C9.74929 4.80671 9.60126 4.86793 9.49215 4.97704C9.38304 5.08615 9.32182 5.23418 9.322 5.38848L9.31787 9.4184L5.28383 9.4184C5.12953 9.41823 4.98149 9.47944 4.87239 9.58855C4.76328 9.69766 4.70206 9.84569 4.70223 9.99999C4.70223 10.3217 4.96209 10.5816 5.28383 10.5816L9.31787 10.5816L9.31787 14.6156C9.31787 14.9374 9.57773 15.1972 9.89947 15.1972C10.2212 15.1972 10.4811 14.9374 10.4811 14.6156L10.4811 10.5816L14.5151 10.5816C14.8368 10.5816 15.0967 10.3217 15.0967 9.99999C15.0967 9.67826 14.8368 9.4184 14.5151 9.4184L10.4811 9.4184L10.4811 5.38435C10.4811 5.07087 10.2171 4.80688 9.90359 4.80688Z" fill="white" /></svg>
                        Import
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}
