import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import {
    TARGETED_FRIEND_REQUEST
} from "_helpers/api";
import Loader from "_components/loader";
import moment from "moment";
import { history } from '_helpers';
import { Dropdown } from "react-bootstrap";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

export default function SendRequest() {
    const { state } = useLocation();
    const { name, account } = state;
    const [status, updateStatus] = useState(true);
    const [tagarray, updateTagarray] = useState({});
    const { user: authUser } = useSelector((x) => x.auth);

    useEffect(() => {
        if (name === "chatsilo") {
            fecthchatsilotagdata();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fecthchatsilotagdata = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        const data = {
            fb_account_id: account
        };
        axios
            .post(`${TARGETED_FRIEND_REQUEST}`, data, {
                headers: headers,
            })
            .then((response) => {
                let finaldata = response.data.data;
                updateTagarray(finaldata);
                updateStatus(false);
            })
            .catch((error) => {
                updateStatus(false);
            });
    };

    return (
        <div className="col-md-10">
            <div className="row">
                <div className="col-md-12 right_side">
                    <div className="row head_name">
                        <div className="col-md-5 left_col">
                            <h3 className="d-flex align-items-center"><ArrowBackOutlinedIcon style={{marginRight:"10px", cursor:"pointer"}} onClick={(e) => history.navigate('userdashboard', { state: { name: account } })} />  Friend request ({tagarray.length})</h3>
                        </div>
                    </div>
                    {status === true ? (
                        <Loader />
                    ) : (
                        <>
                            {/*<div className="row tags_sec">
                                {tagarray.length === 0 ? (
                                    <h4 style={{ textAlign: "center" }}>No data found</h4>
                                ) : (
                                    <>
                                        <div className="row bulk_message_cols account_page">
                                            *tagarray.map((tagdata, i) => (
                                                <div className="message_sec">
                                                    <p>{tagdata.message}</p>
                                                    <span>{moment(new Date(tagdata.created_at)).format(
                                                        "DD/MM/YYYY"
                                                    )}</span>
                                                    <span>{tagdata.sent} sent</span>
                                                    <span>{tagdata.error} failed</span>
                                                </div>
                                            ))*/}
                                            {
                                                tagarray.map((targetdata, i) => (
                                                    <Dropdown className="target_friend_requests" key={`target_friend_requests-${i}`}>
                                                        <Dropdown.Toggle id="dropdown-basic " className="target_friend_request">
                                                            {moment(new Date(targetdata.dates)).format(
                                                                "DD/MM/YYYY"
                                                            )} ({targetdata.names.length})
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            {targetdata.names.map((targetnames, i) => (
                                                                <Dropdown.Item className="dropdownItem d-flex" key={i}> <div>
                                                                    <img src="assets/images/empty.png"
                                                                     onError={(e) => {
                                                                        e.target.onerror = null; // Remove the error handler to prevent infinite loops
                                                                        e.target.src = "assets/images/empty.png"; // Set a default image source
                                                                    }}
                                                                    alt="no" />
                                                                </div><div><p>{targetnames.request_fb_name}</p></div>

                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                ))
                                            /*
                                        </div>
                                    </>
                                )}
                            </div>*/}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
