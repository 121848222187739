import { Row, Col, Spinner } from "react-bootstrap";

function Loader() {
    return (
        <>
            <Row>
                <Col md={12}>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
                        <Spinner animation="border" style={{ color: "#7f3b86" }} />
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default Loader;