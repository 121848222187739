import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Facebook } from "_components/dashboard/facebook";

export default function Home() {
    return (
        <div className="col-md-10 right_side">
            <div className="row head_name">
                <div className="col-md-3 left_col">
                    <h3>Dashboard</h3>
                </div>
            </div>
            <Tabs defaultActiveKey="Facebook" id="uncontrolled-tab-example" className="nav nav-tabs">
                <Tab eventKey="Facebook" title="Facebook" className="nav-item">
                    <Facebook />
                </Tab>
            </Tabs>
        </div>
    );
}