import React from 'react'
import { useState } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import { REACT_APP_URL, RESET_PASSWORD } from '_helpers/api';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

const ResetPassword = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    var token2 = token.split(":");
    token2 = (token2[1]);

    const [password, setPassword] = useState({
        password: "",
        confirm_password: ""
    });

    const changeHandler = (e) => {
        const { value, name } = e.target;
        setPassword(() => {
            return {
                ...password,
                [name]: value
            }
        })

    }

    const submitPassword = () => {
        if (password.password.trim().length === 0 || password.confirm_password.trim().length === 0) {
            toast.error("New Password and Confirm Password is required.", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else if (password.password.trim() === password.confirm_password.trim()) {
            const headers = {
                "Content-Type": "application/json",
            };
            const data = {
                password: password.password,
                passwordToken: token2,
            };
            axios
                .post(`${RESET_PASSWORD}`, data, {
                    headers: headers,
                })
                .then((response) => {
                    if (response.data.status === 200) {
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setTimeout(() => {
                            navigate("/login")
                        }, 2000)
                    } else {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch((error) => {
                    //
                });
        } else {
            toast.error("Confirm Password Must match", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    return (
        <div className="col-md-12 mt-5 pt-5">
            <div className="row login_pages mt-5 pt-5">
                <ToastContainer />
                <div className="col-md-12 text-center">
                    <NavLink to={"/login"}>
                        <img src={`${REACT_APP_URL}assets/images/chatsilo-icon-hd.png`} alt="chatsilo" width="60px"/>
                    </NavLink>
                </div>
                <div className="row m-0">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 p-0">
                        <div className="login_box">
                            <h3>Reset your Password?</h3>
                            <p>Don’t worry, we’ve got you covered</p>
                            <div className="input-group mt-3 mb-3">
                                <span className="input-group-text"><img src="/assets/images/key.png" alt='' /></span>
                                <input type="password" name="password" onChange={changeHandler} value={password.password} className="form-control" placeholder="Enter New Password" />
                            </div>
                            <div className="input-group mt-3 mb-3">
                                <span className="input-group-text"><img src="/assets/images/key.png" alt='' /></span>
                                <input type="password" name="confirm_password" onChange={changeHandler} value={password.confirm_password} className="form-control" placeholder="Confirm New Password" />
                            </div>
                            <div className="col-md-12 d-grid">
                                <button type="submit" disabled={(password.password.trim().length === 0 || password.confirm_password.trim().length === 0)} onClick={submitPassword} className="btn btn-block">Reset Password</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;