import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { primaryAction } from "_store";
import { Link } from "react-router-dom";
import axios from "axios";
import { GET_USER_DATA, REACT_APP_URL } from "_helpers/api";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

export function Sidebar() {
    const authUser = useSelector((x) => x.auth.user);
    const primary_fb_user_id = useSelector((x) => x.auth.primary);
    const dispatch = useDispatch();

    useEffect(() => {
        if (authUser) {
            fecthdata();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUser]);

    const fecthdata = () => {
        if (!primary_fb_user_id) {
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authUser.token}`,
            };
            axios.get(`${GET_USER_DATA}`, {
                headers: headers,
            }).then((response) => {
                let primary_id = response.data.UserDetails.linked_fb_accounts[0].fb_account_id
                dispatch(primaryAction.primary({ actionId: primary_id }));
            }).catch((error) => {
                //Error
            });
        }
    };


    // only show nav when logged in
    if (!authUser) return null;

    return (
        <div className="col-md-2 left_side">
            <ul className="navbar-nav">
                <li className="nav-item  ">
                    <NavLink as={Link} className="nav-link" to="/">
                        <DashboardOutlinedIcon />
                        <span className="menu_links">Dashboard</span>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink as={Link} className="nav-link" to="/tags">
                        <BookmarkBorderOutlinedIcon />
                        <span className="menu_links">Tags</span>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink as={Link} className="nav-link" to="/template">
                        <AssignmentOutlinedIcon />
                        <span className="menu_links">Template</span>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink as={Link} className="nav-link" to="/friendrequest">
                        <GroupAddOutlinedIcon style={{ transform: "scaleX(-1)" }} />
                        <span className="menu_links">Send friend request</span>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink as={Link} className="nav-link" to="/BulkMessage">
                        <MailOutlineOutlinedIcon />
                        <span className="menu_links">Send bulk messages</span>
                    </NavLink>
                </li>
            </ul>
            {authUser.plan_id === 1 &&
                <ul className="navbar-nav foot">
                    <li className="nav-item">
                        <a className="nav-link" href={"https://chatsilo.com/pricing?email="+authUser.email} target="_blank" rel="noreferrer">
                            <img src={`${REACT_APP_URL}assets/images/premium.png`} alt="Chatsilo Premium"/>
                        </a>
                    </li>
                </ul>
            }
        </div>
    );
}