import React from "react";
import { useLocation } from 'react-router-dom';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FacebookTab } from "home/profile/facebookTab";
import { history } from '_helpers';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

export default function UserDashboard() {
    const { state } = useLocation();
    const { name } = state;
    return (
        <div className="col-md-10 right_side">
            <div className="row head_name">
                <div className="col-md-3 left_col">
                    <h3 className="d-flex align-items-center"><ArrowBackOutlinedIcon style={{marginRight:"10px", cursor:"pointer"}} onClick={(e) => history.navigate('profile')} />{name}</h3>
                </div>
            </div>
            <Tabs
                defaultActiveKey="Facebook"
                id="uncontrolled-tab-example"
                className="nav nav-tabs"
            >
                <Tab eventKey="Facebook" title="Facebook" className="nav-item">
                    <FacebookTab fbaccount={name} />
                </Tab>
            </Tabs>
        </div>
    )

}