import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";

import { history, fetchWrapper } from '_helpers';
import { LOGIN_API, PRIMARY_ACCOUNT } from '_helpers/api';

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

const slice = createSlice({ name, initialState, reducers, extraReducers });

export const authActions = { ...slice.actions, ...extraActions };
export const primaryAction = { ...slice.actions };
export const authReducer = slice.reducer;

function createInitialState() {
    return {
        user: JSON.parse(localStorage.getItem('user')),
        error: null,
        primary: localStorage.getItem('FbAccount')
    }
}

function createReducers() {
    return {
        logout,
        primary,
        profile
    };

    function logout(state) {
        state.user = null;
        localStorage.removeItem('user');
        history.navigate('/login');
    }

    function primary(state, action) {
        let primaryId = action.payload.actionId
        localStorage.setItem('FbAccount', primaryId)
        state.primary = primaryId;
    }
    function profile(state, action) {
        let userInfo = action.payload.user
        localStorage.setItem('user', JSON.stringify(userInfo))
        state.user = userInfo;
    }
}

function createExtraActions() {

    return {
        login: login()
    };

    function login() {

        return createAsyncThunk(
            `${name}/login`,
            async ({ email, password }) => await fetchWrapper.post(`${LOGIN_API}`, { email, password })
        );
    }
}

function primaryaccount(token) {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };
    const data = {};
    axios
        .post(`${PRIMARY_ACCOUNT}`, data, {
            headers: headers,
        })
        .then((response) => {
            localStorage.setItem('FbAccount', response.data.primary.fb_account_id);
        })
        .catch((error) => {
            //
        });
}

function createExtraReducers() {
    return {
        ...login()
    };

    function login() {

        var { pending, fulfilled, rejected } = extraActions.login;

        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;
                if (user['status'] === 200) {
                    localStorage.setItem('user', JSON.stringify(user['user']));
                    state.user = user['user'];
                    primaryaccount(user['user'].token)
                    const { from } = history.location.state || { from: { pathname: '/' } };
                    history.navigate(from);
                }
                else {
                    state.error = user['message'];
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
}