import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
    IMAGE_URL,
    GET_TAG_USER,
    USER_TAG_NOTES,
    UPDATE_USER_TAG_NOTES,
    DELETE_TAG_NOTES,
} from "_helpers/api";
import Loader from "_components/loader";
import { toast } from "react-toastify";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment";
import Swal from "sweetalert2";
import SavenotesComponent from "./saveNotes";
import ContactInfoModal from "./contactInfoModal";
import { Form, InputGroup } from "react-bootstrap";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';

export default function ContactTag(props) {
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);
    const [loader, setLoader] = useState(false);
    const [usercontact, setUsercontact] = useState([]);
    const [searchedUserContact, setSearchedUserContact] = useState([]);
    const { user: authUser } = useSelector((x) => x.auth);
    const { primary: primaryid } = useSelector((x) => x.auth);
    const [notesshow, setNotesshow] = useState(false);
    const [usernotes, setUsernotes] = useState([]);
    const [currentnotsname, setCurrentnotsname] = useState("");
    const [showupdate, setShowupdate] = useState(false);
    const [notesuser, setNotesuser] = useState([]);
    const [showContactInfoModal, setShowContactInfoModal] = useState(false);
    const [contactInfoFor, setContactInfoFor] = useState("");
    const [searchKeyword, setSearchKeyword] = useState("");

    const handleShowUpdate = (e, tagdata) => {
        setNotesuser(tagdata);
        setShowupdate(true);
        forceUpdate();
    };
    useEffect(() => {
        setNotesshow(false);
        fecthdata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tagdata.id, primaryid]);

    const fecthdata = () => {
        setLoader(true);
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        const data = {
            tag_id: [props.tagdata.id],
            fb_account_id: primaryid,
        };
        axios
            .post(`${GET_TAG_USER}`, data, {
                headers: headers,
            })
            .then((response) => {
                setLoader(false);
                if (response.data.status === 200) {
                    setUsercontact(response.data.tag_user_data);
                    setSearchedUserContact(response.data.tag_user_data);
                } else {
                    setUsercontact([]);
                    setSearchedUserContact([]);
                }
            })
            .catch((error) => {
                setLoader(false);
            });
    };
    const usernotesget = (e, tagdata) => {
        setNotesshow(true);
        setCurrentnotsname(tagdata.fb_name);
        forceUpdate();
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        const data = {
            fb_user_id: tagdata.fb_user_id,
        };
        axios
            .post(`${USER_TAG_NOTES}`, data, {
                headers: headers,
            })
            .then((response) => {
                setLoader(false);
                if (response.data.status === 200) {
                    setUsernotes(response.data.notes);
                } else {
                    setUsercontact([]);
                    setSearchedUserContact([]);
                }
            })
            .catch((error) => {
                setLoader(false);
            });
    };

    const userContactInfo = (e, tagData) => {
        setContactInfoFor(tagData);
        setShowContactInfoModal(true)
    }

    const usernotesupdate = (e, datavalue, i) => {
        usernotes[i].description = e.target.value;
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        const data = {
            fb_user_id: datavalue.fb_user_id,
            description: e.target.value,
        };
        axios
            .post(`${UPDATE_USER_TAG_NOTES}/${datavalue.id}`, data, {
                headers: headers,
            })
            .then((response) => {
                setLoader(false);
            })
            .catch((error) => { });
    };

    const deletenotes = (e, tagdata, index) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                setUsernotes((current) =>
                    current.filter((array) => array.id !== tagdata.id)
                );
                deleteApi(tagdata.id);
                Swal.fire("Deleted!", "Your file has been deleted.", "success");
            }
        });
    };

    const deleteApi = (id) => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        axios
            .get(DELETE_TAG_NOTES + "/" + id, {
                headers: headers,
            })
            .then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((error) => { });
    };

    const updatemodalfalse = () => {
        setShowupdate(false);
        props.callupdateback();
    };

    const handleSearchChange = (e) => {
        setSearchKeyword(e.target.value)
    }

    useEffect(() => {
        console.log(searchKeyword)
        let temp = [...usercontact];
        temp = temp.filter((item) => item.fb_name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1)
        setSearchedUserContact(temp);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchKeyword])

    return (
        <div className="row px-4">
            <div className="col-md-12 contacts_sec" data-simplebar="init">
                <div className="simplebar-wrapper">
                    <div className="simplebar-height-auto-observer-wrapper">
                        <div className="simplebar-height-auto-observer"></div>
                    </div>
                    <div className="simplebar-mask">
                        <div className="simplebar-offset">
                            <div className="simplebar-content-wrapper">
                                {!notesshow ? (
                                    <div className="simplebar-content">
                                        {props.tagdata.name === undefined ? '' :
                                            <h3>
                                                {props.tagdata.name} ({props.tagdata.users})
                                            </h3>}
                                        {loader === true ? (
                                            <Loader />
                                        ) : (
                                            <>
                                                {usercontact.length === 0 && props.tagdata.name !== undefined ? (
                                                    <h4 style={{ textAlign: "center", marginTop: "20px" }}>
                                                        No data found
                                                    </h4>
                                                ) : (
                                                    <>
                                                        <div className="tag-search mt-3 mb-3">
                                                            <InputGroup>
                                                                <Form.Control placeholder={"Search contacts in " + props.tagdata.name} aria-label="Search tagged contacts" onChange={handleSearchChange} value={searchKeyword} />
                                                                <InputGroup.Text>
                                                                    {
                                                                        searchKeyword.length > 0 ? <ClearIcon onClick={() => { setSearchKeyword("") }} /> : <SearchIcon />
                                                                    }
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        </div>
                                                        <div className="all-contacts-in-this-tag">
                                                            {searchedUserContact.map((tagdata, i) => (
                                                                <div className="row contacts_list" key={i}>
                                                                    <div className="col-9 contact_name">
                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                            {tagdata.profile_pic === "" && tagdata.user_image === null? (
                                                                                <img
                                                                                    src="images/empty.png"
                                                                                    style={{
                                                                                    width: "29px",
                                                                                    height: "29px",
                                                                                    borderRadius: "50%",
                                                                                    marginRight: "15px",
                                                                                    }}
                                                                                    alt={tagdata.fb_name}
                                                                                />
                                                                                ) : (
                                                                                <img
                                                                                    src={tagdata.user_image != null?IMAGE_URL+tagdata.user_image:tagdata.profile_pic}
                                                                                    style={{
                                                                                    width: "29px",
                                                                                    height: "29px",
                                                                                    borderRadius: "50%",
                                                                                    marginRight: "15px",
                                                                                    }}
                                                                                    alt={tagdata.fb_name}
                                                                                    onError={(e) => e.target.src = "assets/images/empty.png"}
                                                                                />
                                                                            )}
                                                                            <h4>{tagdata.fb_name}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3 text-end">
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle
                                                                                id="dropdown-basic"
                                                                                className="dropdown_arrow_toggle"
                                                                            >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M9.3335 5.33203C9.8835 5.33203 10.3335 4.88203 10.3335 4.33203C10.3335 3.78203 9.8835 3.33203 9.3335 3.33203C8.7835 3.33203 8.3335 3.78203 8.3335 4.33203C8.3335 4.88203 8.7835 5.33203 9.3335 5.33203ZM9.3335 7.33203C8.7835 7.33203 8.3335 7.78203 8.3335 8.33203C8.3335 8.88203 8.7835 9.33203 9.3335 9.33203C9.8835 9.33203 10.3335 8.88203 10.3335 8.33203C10.3335 7.78203 9.8835 7.33203 9.3335 7.33203ZM8.3335 12.332C8.3335 11.782 8.7835 11.332 9.3335 11.332C9.8835 11.332 10.3335 11.782 10.3335 12.332C10.3335 12.882 9.8835 13.332 9.3335 13.332C8.7835 13.332 8.3335 12.882 8.3335 12.332Z" fill="#747B84" /></svg>
                                                                            </Dropdown.Toggle>

                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item
                                                                                    onClick={(e) =>
                                                                                        handleShowUpdate(e, tagdata)
                                                                                    }
                                                                                >
                                                                                    Add to tag
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item
                                                                                    onClick={(e) => usernotesget(e, tagdata)}
                                                                                >
                                                                                    Notes
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item
                                                                                    onClick={(e) => userContactInfo(e, tagdata)}
                                                                                >
                                                                                    Contact Info
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <div className="simplebar-content">
                                        <h3><span style={{ cursor: "pointer", marginRight: "10px" }} onClick={() => { setNotesshow(false); }}>
                                            <WestOutlinedIcon />
                                            </span>Notes for {currentnotsname}
                                        </h3>
                                        {loader === true ? (
                                            <Loader />
                                        ) : (
                                            <>
                                                {usernotes.length === 0 ? (
                                                    <h4 style={{ textAlign: "center", marginTop: "20px" }}>
                                                        No data found
                                                    </h4>
                                                ) : (
                                                    <>
                                                        {usernotes.map((tagdata, i) => (
                                                            <div className="row contacts_list" key={i}>
                                                                <textarea
                                                                    className="textareaclass"
                                                                    defaultValue={tagdata.description}
                                                                    onBlur={(e) => usernotesupdate(e, tagdata, i)}
                                                                ></textarea>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        marginTop: "5px",
                                                                    }}
                                                                >
                                                                    <p style={{ flex: 1 }}>
                                                                        {moment(new Date(tagdata.createdDate)).format(
                                                                            "DD/MM/YYYY"
                                                                        )}
                                                                    </p>
                                                                    <img
                                                                        src="assets/images/delete_icon.png"
                                                                        onClick={(e) => deletenotes(e, tagdata, i)}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="simplebar-placeholder"></div>
                </div>
                <div className="simplebar-track simplebar-horizontal">
                    <div className="simplebar-scrollbar"></div>
                </div>
                <div className="simplebar-track simplebar-vertical">
                    <div className="simplebar-scrollbar"></div>
                </div>
                <SavenotesComponent
                    updatemodal={showupdate}
                    functionhide={updatemodalfalse}
                    notesdata={notesuser}
                />
                {showContactInfoModal && <ContactInfoModal handleClose={() => { setShowContactInfoModal(false) }} data={contactInfoFor} show={showContactInfoModal} />}
            </div>
        </div>
    );
}
