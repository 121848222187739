import React from 'react'
import { useState } from 'react'
import axios from 'axios';
import { FORGOT_PASSWORD } from '_helpers/api';
import { NavLink } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";

const ForgotPassword = () => {

    const [email, setEmail] = useState("");
    const [showSpinner, setShowSpinner] = useState(true);

    const changeHandler = (e) => {
        setEmail(e.target.value);

    }
    const submitPasswordMail = () => {
        setShowSpinner(false);
        const headers = {
            "Content-Type": "application/json",
        };
        const data = {
            email: email
        };
        axios
            .post(`${FORGOT_PASSWORD}`, data, {
                headers: headers,
            })
            .then((response) => {
                setShowSpinner(true);
                if (response.data.status === 200) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setEmail("");
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((error) => {
                //
            });

    }

    return (
        <div className="col-md-12 mt-5 pt-5">
            <div className="row login_pages mt-5 pt-5">
                <ToastContainer />
                <div className="col-md-12 text-center">
                    <NavLink to="/login">
                    <img src="assets/images/chatsilo-icon-hd.png" alt="chatsilo" width="60px"/>
                    </NavLink>
                </div>
                <div className="row m-0">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 p-0">
                        <div className="login_box">
                            <h3>Forgot your Password?</h3>
                            <p>Don’t worry, we’ve got you covered</p>
                            <div className="input-group mt-3 mb-3">
                                <span className="input-group-text"><img src="assets/images/envelope.png" alt='email' /></span>
                                <input type="text" onChange={changeHandler} value={email} className="form-control" placeholder="Enter your email" />
                            </div>
                            <div className="col-md-12 d-grid">
                                <button type='submit' className='btn btn-block' disabled={!showSpinner || email.length === 0} onClick={submitPasswordMail}>
                                    {
                                        !showSpinner && <span className="spinner-border spinner-border-sm mr-1"></span>
                                    } Send my Password
                                </button>
                            </div>
                        </div>
                        <h4>Already have a license key? <NavLink className="nav-link" to="/login">
                            <span className="menu_links">Login</span>
                        </NavLink></h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;