import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { history } from '_helpers';
import { CHATSILO_FRIEND_REQUEST_DATA } from "_helpers/api";
import Dropdown from 'react-bootstrap/Dropdown';
import Loader from "_components/loader";
import moment from "moment";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'; 

export default function TargetedFriendRequest() {
    const { state } = useLocation();
    const { name, account } = state;
    const [targetArray, updateTargetArray] = useState({});
    const [status, updateStatus] = useState(true);
    const { user: authUser } = useSelector((x) => x.auth);

    useEffect(() => {
        if (name === "chatsilo") {
            fecthchatsilotaggedfriendrequestdata();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fecthchatsilotaggedfriendrequestdata = () => { 
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        const data = {
            type: 1,
            fb_account_id: account
        };
        axios
            .post(`${CHATSILO_FRIEND_REQUEST_DATA}`, data, {
                headers: headers,
            })
            .then((response) => {
                let finaldata = response.data.data;
                updateTargetArray(finaldata);
                updateStatus(false);
            })
            .catch((error) => {
                updateStatus(false);
            });
    }; 

    return (
        <div className="col-md-10 right_side">
            <div className="row head_name">
                <div className="col-md-5 left_col">
                    <h3 className="d-flex align-items-center">
                        <span>
                            <ArrowBackOutlinedIcon style={{marginRight:"10px", cursor:"pointer"}} onClick={(e) => history.navigate('userdashboard', { state: { name: account } })} />
                            Targeted Friend request ({targetArray.length})
                        </span>
                    </h3>
                </div>
            </div>
            {status === true ? (<Loader />) : (
                <>
                    {
                        targetArray.map((targetdata, i) => (
                            <Dropdown className="target_friend_requests" key={`target_friend_requests-${i}`}>
                                <Dropdown.Toggle id="dropdown-basic " className="target_friend_request">
                                    {moment(new Date(targetdata.dates)).format(
                                        "DD/MM/YYYY"
                                    )} ({targetdata.names.length})
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {targetdata.names.map((targetnames, i) => (
                                        <Dropdown.Item className="dropdownItem d-flex" > <div>
                                            <img src="assets/images/empty.png" alt="no" />
                                        </div><div> {targetnames.request_fb_name}</div>

                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        ))
                    }
                </>
            )}
        </div>
    );
}
