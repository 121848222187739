import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authActions, primaryAction } from "_store";
import axios from "axios";
import { GET_USER_DATA, UPDATE_USER_PROFILE, IMAGE_URL, ADD_NEW_FB_ACCOUNT, UNLINK_FB_ACCOUNT, MAKE_PRIMARY_FB_ACCOUNT } from "_helpers/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "_components/loader";
import { Button, Col, Container, Form, InputGroup, Modal, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import * as Yup from 'yup';
import Swal from "sweetalert2";
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { history } from "_helpers";

const schema = Yup.object().shape({
    facebookProfileURL: Yup.string()
        .url('Invalid URL format')
        .required('Facebook Profile URL is required'),
});

export default function Profile() {
    const dispatch = useDispatch();

    const { user: authUser } = useSelector((x) => x.auth);
    const [userdata, updateUserdata] = useState({});
    const [loader, updateLoader] = useState(true);
    const [showFbAccountLinkModal, setShowFbAccountLinkModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userformdata, updateUserformdata] = useState({
        file: "",
        imagePreviewUrl: "assets/images/profile.png",
    });
    const [userformdataname, updateUserformdataname] = useState("");
    const [facebookProfileURL, setFacebookProfileURL] = useState("");
    const { primary: primaryid } = useSelector((x) => x.auth);
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        fecthdata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fecthdata = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        axios.get(`${GET_USER_DATA}`,{
                headers: headers,
            })
            .then((response) => {
                updateLoader(false);
                updateUserdata(response.data.UserDetails);
                let userData = { ...response.data.UserDetails };
                userData.token = authUser.token;
                delete userData.linked_fb_accounts;
                delete userData.plan;
                dispatch(authActions.profile({ user: userData }));
            })
            .catch((error) => {
                updateLoader(false);
                updateUserdata({});
            });
    };

    const photoUpload = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        updateUserformdata({
            file: file,
            imagePreviewUrl: URL.createObjectURL(e.target.files[0]),
        });
    };

    const namechange = (e) => {
        updateUserformdataname(e.target.value);
    };
    const handleFormSubmit = (e, name) => {
        var username;
        if (userformdataname === "") {
            username = name;
        } else {
            username = userformdataname;
        }

        const formData = new FormData();
        formData.append("image", userformdata.file);
        formData.append("name", username);
        const headers = {
            Authorization: `Bearer ${authUser.token}`,
        };
        axios
            .post(`${UPDATE_USER_PROFILE}`, formData, {
                headers: headers,
            })
            .then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    fecthdata();
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((error) => { });
    };

    const openAddFbAccountLinkForm = () => {
        setValidationErrors({});
        setFacebookProfileURL("")
        setShowFbAccountLinkModal(true)
        setLoading(false)
    }

    const handleClose = () => {
        setFacebookProfileURL("")
        setShowFbAccountLinkModal(false)
    }

    const extractFbAccountIdFromProfileURL = (profileURL) => {
        const regex = /facebook\.com\/(?:[^/]+\/)?(?:profile\.php\?id=)?(\d+)|facebook\.com\/([^/?]+)/i;
        const match = profileURL.match(regex);
        if (match) {
            if (match[1]) {
                return match[1];
            }
            if (match[2]) {
                return match[2];
            }
        }
        return null;
    }

    const handleFbProfileURLSubmit = () => {
        const valuesToValidate = {
            facebookProfileURL,
        };

        schema
            .validate(valuesToValidate, { abortEarly: false })
            .then(() => {
                setLoading(true)
                setValidationErrors({});

                let fb_account_id = extractFbAccountIdFromProfileURL(facebookProfileURL);
                if (!fb_account_id) {
                    setValidationErrors({
                        facebookProfileURL: "Invalid Facebook Profile URL",
                    });
                    setLoading(false)
                    return false;
                }

                let isAlreadyLinked = userdata.linked_fb_accounts.some((account) => account.fb_account_id === fb_account_id);
                if (isAlreadyLinked) {
                    setValidationErrors({
                        facebookProfileURL: "This Facebook account is already linked.",
                    });
                    setLoading(false)
                    return false;
                }

                const headers = {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authUser.token}`,
                };

                const data = {
                    fb_account_id: fb_account_id,
                };

                axios
                    .post(`${ADD_NEW_FB_ACCOUNT}`, data, {
                        headers: headers,
                    })
                    .then((response) => {
                        setFacebookProfileURL("")
                        if (response.data.status === 200) {
                            toast.success("New Facebook account linked successfully", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        } else {
                            toast.error(response.data.msg, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                        fecthdata()
                        setLoading(false)
                    })
                    .catch((error) => {
                        setFacebookProfileURL("")
                        toast.error("An error occurred while linking the Facebook account", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setLoading(false)
                    });
            })
            .catch((errors) => {
                const validationErrors = {};
                errors.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                setValidationErrors(validationErrors);
            });
    }

    const handleOnChangeFacebookProfileURL = (e) => {
        setFacebookProfileURL(e.target.value)
    }

    const confirmDeletion = (fb_account_id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#572F5B",
            cancelButtonColor: "#D9D9D9",
            confirmButtonText: "Yes,delete",
        }).then((result) => {
            if (result.isConfirmed) {
                const headers = {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authUser.token}`,
                };
                const data = {
                    fb_account_id: fb_account_id,
                };
                axios
                    .post(`${UNLINK_FB_ACCOUNT}`, data, {
                        headers: headers,
                    })
                    .then((response) => {
                        if (response.data.status === 200) {
                            toast.success(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                        fecthdata()
                    })
                    .catch((error) => {
                        toast.error("An error occurred while unlinking your Facebook account", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    });
            }
        });
    }

    const markItAsPrimaryAccount = (fb_account_id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to mark this account as Primary FB Account!",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#572F5B",
            cancelButtonColor: "#D9D9D9",
            confirmButtonText: "Yes, Continue",
        }).then((result) => {
            if (result.isConfirmed) {
                const headers = {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authUser.token}`,
                };
                const data = {
                    fb_account_id: fb_account_id,
                };
                axios
                    .post(`${MAKE_PRIMARY_FB_ACCOUNT}`, data, {
                        headers: headers,
                    })
                    .then((response) => {
                        if (response.data.status === 200) {
                            toast.success(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            dispatch(primaryAction.primary({ actionId: fb_account_id }));
                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                        fecthdata()
                    })
                    .catch((error) => {
                        toast.error("An error occurred while marking FB account as Primary account", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    });
            }
        });
    }

    return (
        <div className="col-md-10 right_side accounts_page">
            <ToastContainer />
            <div className="row head_name">
                <div className="col-md-7 left_col">
                    <h3>Account settings</h3>
                    <p>Here you can edit public information about yourself.</p>
                    <p> The changes will be displayed for other users.</p>
                </div>
                <div className="col-md-5 right_col align-items-center justify-content-end d-flex">
                    <div className="d-flex justify-content-end">
                        <div className="actions action_last_col">
                            <Button className="add_tag button_global" variant="primary" onClick={() => openAddFbAccountLinkForm()}>
                                <img src="assets/images/add.png" alt="" />
                                Add account
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row account_profile">
                <div className="col-md-5">
                    <div className="profile_cols">
                        <h3>Your profile</h3>

                        <label
                            htmlFor="photo-upload"
                            className="custom-file-upload fas align-items-center justify-content-center mb-3 mt-2"
                        >
                            <div className="img-wrap img-upload">
                                <img
                                    htmlFor="photo-upload"
                                    onError={(e) => {
                                        e.target.onerror = null; // Remove the error handler to prevent infinite loops
                                        e.target.src = "assets/images/profile-2.png"; // Set a default image source
                                    }}
                                    alt=""
                                    className="upload-image-preview"
                                    src={
                                        (userdata?.image !== null && userdata?.image !== "" && userdata?.image !== undefined) &&
                                            userformdata.imagePreviewUrl.indexOf("blob") === -1
                                            ? `${IMAGE_URL}images/${userdata.image}`
                                            : userformdata.imagePreviewUrl
                                    }
                                />
                                <span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="48"
                                        height="50"
                                        viewBox="0 0 48 50"
                                        fill="none"
                                    >
                                        <g filter="url(#filter0_d_272_4288)">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M16.4999 26C16.4999 21.8579 19.8578 18.5 23.9999 18.5C28.1421 18.5 31.4999 21.8579 31.4999 26C31.4999 30.1421 28.1421 33.5 23.9999 33.5C19.8578 33.5 16.4999 30.1421 16.4999 26ZM23.9999 21.5C21.5147 21.5 19.4999 23.5147 19.4999 26C19.4999 28.4853 21.5147 30.5 23.9999 30.5C26.4852 30.5 28.4999 28.4853 28.4999 26C28.4999 23.5147 26.4852 21.5 23.9999 21.5Z"
                                                fill="white"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M21.2322 11.5C19.1419 11.5 17.4473 13.1946 17.4473 15.2849C17.4473 16.6168 16.4249 17.7257 15.0973 17.8335L10.6381 18.1956C9.76258 18.2667 9.04252 18.9142 8.8791 19.7773C7.98161 24.5173 7.91469 29.3772 8.68132 34.1401L8.87583 35.3485C9.05951 36.4897 9.99878 37.358 11.1509 37.4515L15.0355 37.767C21.002 38.2516 26.9979 38.2516 32.9643 37.767L36.849 37.4515C38.0011 37.358 38.9404 36.4897 39.1241 35.3485L39.3186 34.1401C40.0852 29.3772 40.0183 24.5173 39.1208 19.7773C38.9574 18.9142 38.2373 18.2667 37.3618 18.1956L32.9026 17.8335C31.575 17.7257 30.5526 16.6168 30.5526 15.2849C30.5526 13.1946 28.858 11.5 26.7677 11.5H21.2322ZM14.4595 14.8754C14.6712 11.3189 17.6225 8.5 21.2322 8.5H26.7677C30.3774 8.5 33.3287 11.3189 33.5404 14.8754L37.6046 15.2055C39.8265 15.3859 41.6537 17.0289 42.0684 19.2192C43.0306 24.3007 43.1023 29.5108 42.2804 34.6169L42.0859 35.8253C41.6827 38.3304 39.6209 40.2363 37.0918 40.4417L33.2072 40.7572C27.0791 41.2549 20.9208 41.2549 14.7927 40.7572L10.9081 40.4417C8.379 40.2363 6.31717 38.3304 5.91395 35.8253L5.71945 34.6169C4.89758 29.5108 4.96932 24.3007 5.93147 19.2192C6.34618 17.0289 8.17345 15.3859 10.3953 15.2055L14.4595 14.8754Z"
                                                fill="white"
                                            />
                                        </g>
                                        <defs>
                                            <filter
                                                id="filter0_d_272_4288"
                                                x="-4"
                                                y="0"
                                                width="56"
                                                height="56"
                                                filterUnits="userSpaceOnUse"
                                                colorInterpolationFilters="sRGB"
                                            >
                                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix
                                                    in="SourceAlpha"
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha"
                                                />
                                                <feOffset dy="4" />
                                                <feGaussianBlur stdDeviation="2" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_272_4288"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="effect1_dropShadow_272_4288"
                                                    result="shape"
                                                />
                                            </filter>
                                        </defs>
                                    </svg>
                                </span>
                            </div>
                            <input id="photo-upload" type="file" onChange={photoUpload} />
                        </label>
                        <form>
                            <div className="input-group mb-3">
                                <span className="input-group-text">
                                    <img src="assets/images/status.png" alt="" />
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={userdata.name}
                                    placeholder="Team status"
                                    onChange={namechange}
                                />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">
                                    <img src="assets/images/envelope.png" alt="" />
                                </span>
                                <input
                                    type="email"
                                    className="form-control"
                                    defaultValue={userdata.email}
                                    placeholder="Useremail@gmail.com"
                                    disabled
                                />
                            </div>
                            <div className="input-group">
                                <span className="input-group-text">
                                    <img src="assets/images/icon.png" alt="" />
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={userdata.license_key}
                                    placeholder="Licence key"
                                    disabled
                                />
                            </div>
                        </form>
                        <div className="row actions mt-3">
                            <div className="col-md-12">
                                <Button
                                    variant="primary"
                                    className="add_tag button_global"
                                    onClick={(e) => handleFormSubmit(e, userdata.name)}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="profile_cols">
                        <h3>Team profile</h3>
                        {loader === true ? (
                            <Loader />
                        ) : (
                            <div className="row">
                                {Object.entries(userdata).length === 0 ? (
                                    <h4 style={{ textAlign: "center" }}>No data found</h4>
                                ) : (
                                    <>
                                        {userdata.linked_fb_accounts.map((account, i) => (
                                            <div className="col-md-3 team_Profile_cols_sec" key={i}>
                                                <div
                                                    className={`position-relative team_Profile_cols ${(primaryid === account.fb_account_id) ? 'active' : ''} ${account.is_primary ? 'primay-account' : ''}`}
                                                >
                                                    <div className="text-center">
                                                        <img 
                                                            className="linked_user_image" 
                                                            src={
                                                                account?.account_image_url?.startsWith('https') 
                                                                ? account.account_image_url 
                                                                : account.account_image_url?`${IMAGE_URL}${account.account_image_url}`:'assets/images/empty.png'
                                                            } 
                                                            alt="User"
                                                            onError={(e) => e.target.src = "assets/images/empty.png"}
                                                        />
                                                        <h4 className="h4_change">
                                                            {account.fb_account_id}
                                                        </h4>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-evenly w-100">
                                                        <div>
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                delay={{ show: 250, hide: 250 }}
                                                                overlay={<Tooltip>See the details</Tooltip>}
                                                            >
                                                                <span onClick={(e) => history.navigate('userdashboard', { state: { name: account.fb_account_id } })}>
                                                                    <VisibilityIcon />
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div>
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                delay={{ show: 250, hide: 250 }}
                                                                overlay={<Tooltip>{account.is_primary ? "Primary Account" : "Mark it as Primary Account"}</Tooltip>}
                                                            >
                                                                {
                                                                    account.is_primary ?
                                                                        <span><StarIcon /></span> :
                                                                        <span onClick={() => markItAsPrimaryAccount(account.fb_account_id)}><StarOutlineIcon style={{ cursor: "pointer" }} /></span>
                                                                }
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div>
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                delay={{ show: 250, hide: 250 }}
                                                                overlay={<Tooltip>Unlink this account</Tooltip>}
                                                            >
                                                                <span onClick={() => { confirmDeletion(account.fb_account_id) }}><DeleteIcon /></span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* ))} */}
            {
                showFbAccountLinkModal &&
                <Modal show={showFbAccountLinkModal} onHide={() => { handleClose() }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Link Facebook Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <Form.Label>Facebook Profile URL</Form.Label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">
                                            <PersonAddIcon />
                                        </InputGroup.Text>
                                        <Form.Control
                                            placeholder="Facebook Profile URL"
                                            aria-label="Facebook Profile URL"
                                            aria-describedby="basic-addon1"
                                            maxLength={1000}
                                            value={facebookProfileURL}
                                            onChange={handleOnChangeFacebookProfileURL}
                                        />
                                        {validationErrors.facebookProfileURL && (
                                            <div className="error-message">
                                                {validationErrors.facebookProfileURL}
                                            </div>
                                        )}
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" style={{ width: "150px", height: "58px", zoom: "0.7", fontSize: "14px" }} onClick={() => { handleClose() }}>
                            Close
                        </Button>
                        <Button disabled={loading} className="add_tag button_global" style={{ width: "150px", height: "58px", zoom: "0.7", fontSize: "14px" }} variant="primary" onClick={() => { handleFbProfileURLSubmit() }}>
                            {loading ? <Spinner animation="border" style={{ color: "#7f3b86" }} /> : <>Save Changes</>}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </div>
    );
}
