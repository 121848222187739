import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ListManager } from 'react-beautiful-dnd-grid';
import axios from 'axios';
import {
    GET_TEMPLATE,
    DELETE_TEMPLATE,
    SAVE_TEMPLATE,
    UPDATE_TEMPLATE,
    UPDATE_TEMPLATE_ORDER
} from '_helpers/api';
import Loader from '_components/loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import TemplateMessage from './templateMessage';
import Select from 'react-dropdown-select';
import { Button } from 'react-bootstrap';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CreateIcon from '@mui/icons-material/Create';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

export default function Template() {
    const { user: authUser } = useSelector((x) => x.auth);
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);
    let [status, updateStatus] = useState(true);
    const [editField, updateEditField] = useState(false);
    const [selectedinput, setSelectedinput] = useState(null);
    const [selectedcheck, setSelectedcheck] = useState(0);
    const [addclass, setAddClass] = useState(0);
    let [templatearray, updateTemplatearray] = useState([]);
    let [newcopytemplatearray, updateNewcopyTemplatearray] = useState([]);
    let [selectedtemplatearray, updateSelectedTemplatearray] = useState([]);
    let [fieldvalue, updateFieldvalue] = useState('');
    let [checkcontanct, setCheckcontanct] = useState(false);
    let [templatemessagenew, setTemplatemessagenew] = useState([]);

    useEffect(() => {
        fecthdata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fecthdata = () => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authUser.token}`,
        };
        axios.get(`${GET_TEMPLATE}`, {
            headers: headers,
        }).then((response) => {
            let finaldata = response.data.template_data;
            updateTemplatearray(finaldata);
            updateNewcopyTemplatearray(finaldata);
            updateStatus(false);
        }).catch((error) => {
            updateStatus(false);
        });
    };

    const handleChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            updateSelectedTemplatearray([...selectedtemplatearray, parseInt(value)]);
        } else {
            updateSelectedTemplatearray(
                selectedtemplatearray.filter((e) => e !== parseInt(value))
            );
        }
    };
    const allSelect = (e) => {
        if (e.target.checked) {
            var data = [];
            templatearray.map((key, index) => {
                data.push(key.id);
                return null;
            });
            updateSelectedTemplatearray(data);
        } else {
            updateSelectedTemplatearray([]);
        }
    };

    const getInput = () => {
        if (selectedtemplatearray.length === 0) {
        } else {
            Swal.fire({
                title: 'Delete template folder',
                text: 'Are you sure you want to delete Untimely updates and all the templates in it?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#572F5B',
                cancelButtonColor: '#D9D9D9',
                confirmButtonText: 'Yes, delete',
            }).then((result) => {
                if (result.isConfirmed) {
                    deleteApi();
                }
            });
        }
    };

    const deleteApi = () => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authUser.token}`,
        };
        const data = {
            id: selectedtemplatearray,
        };
        axios
            .post(DELETE_TEMPLATE, data, {
                headers: headers,
            })
            .then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    fecthdata();
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((error) => { });
    };
    const showaddtag = () => {
        updateEditField(true);
    };
    const addfiledvalue = (e) => {
        updateFieldvalue(e.target.value);
    };

    const savedtemplate = (e) => {
        if (fieldvalue === '') {
            toast.error('Template Name Can not Blank', {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authUser.token}`,
            };
            const data = {
                name: fieldvalue,
            };
            axios
                .post(SAVE_TEMPLATE, data, {
                    headers: headers,
                })
                .then((response) => {
                    if (response.data.status === 200) {
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        fecthdata();
                        updateEditField(false);
                    } else {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch((error) => { });
        }
    };

    const sorttemplate = (data) => {
        var tempdata = templatearray;
        if (data[0].value === 'A-Z') {
            tempdata.sort((a, b) => a.name.localeCompare(b.name));
        } else if (data[0].value === 'Z-A') {
            tempdata.sort((a, b) => b.name.localeCompare(a.name));
        } else if (data[0].value === 'oldest-first-asc') {
            tempdata.sort((a, b) => a.id - b.id);
        } else if (data[0].value === 'newest-first-desc') {
            tempdata.sort((a, b) => b.id - a.id);
        } else {
            tempdata = templatearray;
        }
        updateTemplatearray(tempdata);
        forceUpdate();
    };
    const options = [
        {
            label: 'By A-Z',
            value: 'A-Z',
        },
        {
            label: 'By Z-A',
            value: 'Z-A',
        },
        {
            label: 'Oldest first',
            value: 'oldest-first-asc',
        },
        {
            label: 'Newest first',
            value: 'newest-first-desc',
        },
    ];
    const searchinput = (data) => {
        var input_value = data.target.value;
        if (input_value === '') {
            updateTemplatearray(newcopytemplatearray);
            forceUpdate();
        } else {
            var tempdata = newcopytemplatearray;
            const filteredArray = tempdata.filter((item) =>
                item.name.toLowerCase().includes(input_value.toLowerCase())
            );
            if (filteredArray.length === 0) {
                updateTemplatearray([]);
            } else {
                updateTemplatearray(filteredArray);
            }
            forceUpdate();
        }
    };

    const handleValue = (e) => {
        setSelectedinput(e.target.value);
    };
    const edittemplate = (id, index) => {
        const indexToUpdate = templatearray.findIndex(item => item.id === id);

        console.log(templatearray, "index", index)
        templatearray[indexToUpdate].name = selectedinput;
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authUser.token}`,
        };
        const data = {
            name: selectedinput,
        };
        axios
            .post(UPDATE_TEMPLATE + '/' + id, data, {
                headers: headers,
            })
            .then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((error) => {
                updateStatus(false);
            });
        setSelectedcheck(0);
        setSelectedinput(null);
    };

    const closeTemplteField = () => {
        updateEditField(false);
    };
    const showtemplate = (e, id, name) => {
        e.preventDefault();
        setSelectedcheck(id);
        setSelectedinput(name);
    };

    const checktemplatemessage = (e, data) => {
        setAddClass(data.id);
        setCheckcontanct(true);
        setTemplatemessagenew(data);
        forceUpdate();
    };
    function sortList(list) {
        let newList = list
            .slice()
            .sort((first, second) => first.order_num - second.order_num);
        let newTagsData = newList.reduce((previousValue, currentValue) => {
            let temp = {
                template_id: currentValue.id,
                order_num: previousValue.length + 1,
            };
            previousValue.push(temp);
            return previousValue;
        }, []);
        updateSortSettingInDB(newTagsData);
        return newList;
    }

    const sorttag = (data) => {
        var tempdata = templatearray;
        if (data[0].value === 'A-Z') {
            tempdata.sort((a, b) => a.name.localeCompare(b.name));
        } else if (data[0].value === 'Z-A') {
            tempdata.sort((a, b) => b.name.localeCompare(a.name));
        } else if (data[0].value === 'oldest-first-asc') {
            tempdata.sort((a, b) => a.id - b.id);
        } else if (data[0].value === 'newest-first-desc') {
            tempdata.sort((a, b) => b.id - a.id);
        } else if (data[0].value === 'custom') {
            tempdata.sort((a, b) => a.order_num - b.order_num);
        } else {
            tempdata = templatearray;
        }
        updateTemplatearray(tempdata);
        forceUpdate();
    };

    const handleOnDragEnd = (sourceIndex, destinationIndex) => {
        if (destinationIndex === sourceIndex) {
            return;
        }
        let list = [...templatearray];

        if (destinationIndex === 0) {
            list[sourceIndex].order_num = list[0].order_num - 1;
            updateTemplatearray(sortList(list));
            sorttag([{ value: 'custom' }]);
            return;
        }

        if (destinationIndex === list.length - 1) {
            list[sourceIndex].order_num = list[list.length - 1].order_num + 1;
            updateTemplatearray(sortList(list));
            sorttag([{ value: 'custom' }]);
            return;
        }

        if (destinationIndex < sourceIndex) {
            list[sourceIndex].order_num =
                (list[destinationIndex].order_num +
                    list[destinationIndex - 1].order_num) /
                2;
            updateTemplatearray(sortList(list));
            sorttag([{ value: 'custom' }]);
            return;
        }

        list[sourceIndex].order_num =
            (list[destinationIndex].order_num +
                list[destinationIndex + 1].order_num) /
            2;
        updateTemplatearray(sortList(list));
        sorttag([{ value: 'custom' }]);
    };


    const updateSortSettingInDB = (sortedData) => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        axios
            .post(UPDATE_TEMPLATE_ORDER, { changedorder: sortedData }, {
                headers: headers,
            }).then((response) => { }).catch((error) => { });
    }

    return (
        <div className="col-md-10 right_side">
            <div className="row head_name">
                <div className="col-md-5 left_col">
                    <h3>My templates</h3>
                </div>
                <ToastContainer />
            </div>
            <div className="row tags_sec templates_sec">
                <div className="col-md-4 template_col_sec">
                    <div className="template_cols">
                        <div className="d-flex">
                            <div className="head_top search_field ">
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => searchinput(e)}
                                        placeholder="Search"
                                    />
                                    <span className="input-group-text">
                                        <SearchIcon style={{ fill: "#572f5b" }} />
                                    </span>
                                </div>
                            </div>
                            <div className="head_top actions action_last_col">
                                <div className="dropdown">
                                    <Select
                                        className="dropdown_tag_new"
                                        options={options}
                                        onChange={(values) => sorttemplate(values)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-grid">
                            <Button className="add_folder btn-block" onClick={showaddtag}>
                                <img src="assets/images/create_folder.png" alt="" />
                                add new folder
                            </Button>
                        </div>
                        {templatearray.length > 0 ? (
                            <>
                                <div className="d-flex select_folder">
                                    <div className="select_folder_col">
                                        <input
                                            type="checkbox"
                                            onChange={(e) => allSelect(e)}
                                            checked={
                                                selectedtemplatearray.length === templatearray.length
                                            }
                                        />
                                        <span>Select all folders</span>
                                    </div>
                                    <div className="select_folder_col_1">
                                        <Button variant='light' onClick={getInput} style={{ padding: "0px", background: "transparent", border: "0px" }}>
                                            <DeleteIcon style={{ fill: "#572f5b" }} />
                                        </Button>
                                    </div>
                                </div>
                            </>
                        ) : null}

                        <>
                            {editField && (
                                <div className="d-flex template_col">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => addfiledvalue(e)}
                                    />
                                    <Button variant='light' onClick={savedtemplate} style={{ padding: "0px", margin: "0px 10px", background: "transparent", border: "0px" }}>
                                        <CheckIcon style={{ fill: "#572f5b" }} />
                                    </Button>
                                    <Button variant='light' onClick={closeTemplteField} style={{ padding: "0px", background: "transparent", border: "0px" }}>
                                        <CloseIcon style={{ fill: "#572f5b" }} />
                                    </Button>
                                </div>
                            )}
                        </>
                        {status === true ? (
                            <Loader />
                        ) : (
                            <>
                                {templatearray.length === 0 ? (
                                    <h4 style={{ textAlign: 'center', marginTop: '15px' }}>
                                        No data found
                                    </h4>
                                ) : (
                                    <div className="scroll-area">
                                        <ListManager
                                            items={templatearray}
                                            direction="vertical"
                                            maxItems={1}
                                            onDragEnd={handleOnDragEnd}
                                            render={(item, i) => (
                                                <>
                                                    {' '}
                                                    <div className="d-flex template_col" key={item.id}>
                                                        <div style={{ lineHeight: '50px' }}>
                                                            <input
                                                                type="checkbox"
                                                                className="item"
                                                                id={item.id}
                                                                name="contactId"
                                                                value={item.id}
                                                                checked={selectedtemplatearray.includes(
                                                                    item.id
                                                                )}
                                                                onChange={(e) => handleChange(e)}
                                                            />
                                                        </div>
                                                        <div className="template_tags">
                                                            {selectedcheck === item.id ? (
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                    }}
                                                                >
                                                                    <input
                                                                        defaultValue={item.name}
                                                                        type="text"
                                                                        className="form-control"
                                                                        style={{
                                                                            background: 'none',
                                                                            border: 'none',
                                                                            flex: '0.9',
                                                                        }}
                                                                        onChange={handleValue}
                                                                        readOnly={
                                                                            selectedcheck === item.id ? false : true
                                                                        }
                                                                    />
                                                                    <Button data-id={item.id} variant='light' onClick={(e) => edittemplate(item.id, i)} style={{ padding: "0px", margin: "0px 10px", background: "transparent", border: "0px" }}>
                                                                        <CheckIcon style={{ fill: "#572f5b" }} />
                                                                    </Button>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                    }}
                                                                >
                                                                    <h3
                                                                        className={
                                                                            addclass === item.id
                                                                                ? 'template_button_active'
                                                                                : ''
                                                                        }
                                                                        style={{ flex: '0.9' }}
                                                                        onClick={(e) =>
                                                                            checktemplatemessage(e, item)
                                                                        }
                                                                    >
                                                                        {item.name} ({item.countmessage_count})
                                                                    </h3>
                                                                    <Button variant='light' onClick={(e) => showtemplate(e, item.id, item.name)} style={{ padding: "0px", margin: "0px 10px", background: "transparent", border: "0px" }}>
                                                                        <CreateIcon style={{ fill: "#572f5b" }} />
                                                                    </Button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {checkcontanct && (
                    <TemplateMessage
                        templatedata={templatemessagenew}
                        tagcomponentarray={templatearray}
                    />
                )}
            </div>
        </div>
    );
}
