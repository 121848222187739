export const BASE_URL = process.env.REACT_APP_API_URL;
export const WEBAPP_BASE_URL = process.env.REACT_APP_EXTENSION_API_URL;
export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
export const REACT_APP_URL = process.env.REACT_APP_URL;

export const LOGIN_API = BASE_URL + '/login';
export const FORGOT_PASSWORD = WEBAPP_BASE_URL + '/password/create';
export const RESET_PASSWORD = WEBAPP_BASE_URL + '/password/reset';
export const CHANGE_PASSWORD = WEBAPP_BASE_URL + '/change_password';
export const GET_USER_DATA = WEBAPP_BASE_URL + '/me';
export const PRIMARY_ACCOUNT = WEBAPP_BASE_URL + '/primary_account';
export const UPDATE_USER_PROFILE = WEBAPP_BASE_URL + '/update_profile';
export const GET_TEMPLATE = WEBAPP_BASE_URL + '/get_template';
export const GET_TAG_WITH_COUNTER = WEBAPP_BASE_URL + '/get_tags_with_counter';
export const UNLINK_FB_ACCOUNT = WEBAPP_BASE_URL + '/unlink_fb_account';
export const MAKE_PRIMARY_FB_ACCOUNT = WEBAPP_BASE_URL + '/make_primary_fb_account';
export const UPDATE_USER_TAG_NOTES = WEBAPP_BASE_URL + '/save_user_note';
export const DELETE_TAG_NOTES = WEBAPP_BASE_URL + '/delete_user_note';
export const SENT_FRIEND_REQUEST = WEBAPP_BASE_URL + '/get_sent_friend_request_with_count';
export const SENT_BULK_MESSAGES = WEBAPP_BASE_URL + '/get_sent_bulk_messages_with_count';
export const SENT_FRIEND_REQUEST_DELETE = WEBAPP_BASE_URL + '/delete_multiple_sent_friend_request';
export const SENT_BULK_MESSAGE_DELETE = WEBAPP_BASE_URL + '/delete_multiple_sent_bulk_message';
export const USER_TAG_NOTES = WEBAPP_BASE_URL + '/get_notes';
export const UPDATE_TAG_POPUP_NEW = WEBAPP_BASE_URL + '/update_tag_from_popup';
export const SAVE_TAG = WEBAPP_BASE_URL + '/save_tag';
export const GET_TAG_USER = WEBAPP_BASE_URL + '/get_tag_user';
export const DELETE_TEMPLATE = WEBAPP_BASE_URL + '/template_delete';
export const SAVE_TEMPLATE = WEBAPP_BASE_URL + '/save_template';
export const UPDATE_TEMPLATE = WEBAPP_BASE_URL + '/update_template';
export const TEMPLATE_MESSAGE = WEBAPP_BASE_URL + '/get_template_message';
export const DELETE_TEMPLATE_MESSAGE = WEBAPP_BASE_URL + '/template_message_delete';
export const ADD_TEMPLATE_MESSAGE = WEBAPP_BASE_URL + '/save_message';
export const UPDATE_TAG_ORDER = WEBAPP_BASE_URL + '/change_tag_order';
export const UPDATE_TEMPLATE_ORDER = WEBAPP_BASE_URL + '/change_template_order';
export const UPDATE_MESSAGE_ORDER = WEBAPP_BASE_URL + '/change_message_order';
export const DELETE_TAG = WEBAPP_BASE_URL + '/delete_tag';
export const UPDATE_TAG_COLOR_ND_NAME = WEBAPP_BASE_URL + '/update_tag_color_nd_name';
export const EXPORT_TAG = WEBAPP_BASE_URL + '/export_tag';
export const IMPORT_TAG = WEBAPP_BASE_URL + '/import_tag';
export const TARGETED_FRIEND_REQUEST = WEBAPP_BASE_URL + '/targeted_friend_request';
export const CHATSILO_FACEBOOK_TAB = WEBAPP_BASE_URL + '/chatsilo_data_count';
export const CHATSILO_FRIEND_REQUEST_DATA = WEBAPP_BASE_URL + '/chatsilo_friend_request_data';
export const CHATSILO_BULK_MESSAGE_SENT_DATA = WEBAPP_BASE_URL + '/chatsilo_bulk_message_data';
export const COUNT_USER_DATA_API = WEBAPP_BASE_URL + '/count_user_data';
export const UPDATE_TEMPLATE_MESSASGE = WEBAPP_BASE_URL + '/update_message';

export const EXTENSION_API_URL = IMAGE_URL + "extension";
export const GET_USER_CONTACT_INFO = EXTENSION_API_URL + '/get_user_contact_info';
export const ADD_UPDATE_USER_CONTACT_INFO = EXTENSION_API_URL + '/add_user_contact_info';
export const ADD_NEW_FB_ACCOUNT = EXTENSION_API_URL + '/add_fb_account';

/*2024 Routes*/
export const SEARCH_CS_ITEMS = WEBAPP_BASE_URL + '/search_cs_items';
export const GET_NOTIFICATIONS_FOR_USERS = WEBAPP_BASE_URL + '/get_notifications_for_user';
export const MARK_AS_READ_NOTIFICATION = WEBAPP_BASE_URL + '/mark_as_read_notification';
export const MARK_AS_ALL_READ_NOTIFICATION = WEBAPP_BASE_URL + '/mark_as_all_read_notification';
export const DELETE_NOTIFICATION = WEBAPP_BASE_URL + '/delete_notification';