import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Card, Col } from "react-bootstrap";
import { COUNT_USER_DATA_API } from "_helpers/api";
import Loader from "_components/loader";
export { Facebook };

function Facebook() {
    const { user: authUser } = useSelector((x) => x.auth);
    let [data, updateData] = useState({
        tag: 0,
        template: 0,
        bulkmessage: 0,
        friendrequest: 0,
        taggedfriendrequest: 0,
    });
    let [status, updateStatus] = useState(true);

    useEffect(() => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        const data = {};
        axios
            .post(`${COUNT_USER_DATA_API}`, data, {
                headers: headers,
            })
            .then((response) => {
                let finaldata = response.data.data;
                updateData({
                    tag: finaldata.tag,
                    template: finaldata.template,
                    bulkmessage: finaldata.bulkmessage,
                    friendrequest: finaldata.sendrequest,
                    taggedfriendrequest: finaldata.taggeduser,
                });
                updateStatus(false);
            })
            .catch((error) => {
                updateStatus(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // only show nav when logged in
    if (!authUser) return null;
    return (
        <>
            {status === true ? (
                <Loader />
            ) : (
                <Row>
                    <Col md={3}>
                        <Card className="columns border-0">
                            <Row>
                                <Col md={12}>
                                    <h4>
                                        <img src="assets/images/fb_tags.png" alt="" /> Tags
                                    </h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="d-flex justify-content-between">
                                    <h3>{data.tag}</h3>
                                    {/*<img src="assets/images/arrow.png" />*/}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="columns border-0">
                            <Row>
                                <Col md={12}>
                                    <h4>
                                        <img src="assets/images/fb_templates.png" alt="" /> Templates
                                    </h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="d-flex justify-content-between">
                                    <h3>{data.template}</h3>
                                    {/*<img src="assets/images/arrow.png" />*/}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="columns border-0">
                            <Row>
                                <Col md={12}>
                                    <h4>
                                        <img src="assets/images/fb_bulk_message.png" alt="" /> Bulk
                                        Messages
                                    </h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="d-flex justify-content-between">
                                    <h3>{data.bulkmessage}</h3>
                                    {/*<img src="assets/images/arrow.png" />*/}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="columns border-0">
                            <Row>
                                <Col md={12}>
                                    <h4>
                                        <img src="assets/images/fb_Friend_Request.png" alt="" /> Friend
                                        request{" "}
                                    </h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="d-flex justify-content-between">
                                    <h3>{data.friendrequest}</h3>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    {/* <Col md={3}>
                        <Card className="columns border-0">
                            <Row>
                                <Col md={12}>
                                    <h4>
                                        <img src="assets/images/fb_Friend_Request.png" alt="" /> Targeted
                                        Friend request{" "}
                                    </h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="d-flex justify-content-between">
                                    <h3>{data.taggedfriendrequest}</h3>
                                </Col>
                            </Row>
                        </Card>
                    </Col> */}
                </Row>
            )}
        </>
    );
}