import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import { history } from "_helpers";
import { PrivateRoute } from "_components";
import { NavbarComponent } from "_components/include/Navbar";
import { Sidebar } from "_components/include/Sidebar";

import Home from "home/Home.jsx";
import Tags from "home/tags/tags";
import Template from "home/template/template";
import FriendRequest from "home/sendFriendRequest/friendRequest";
import Profile from "home/profile/profile";

import { Login } from "login";
import UserDashboard from "home/profile/userDashboard";
import ChangePassword from "home/profile/changePassword";
import TagsData from "home/profile/facebook/tagsData";
import SendRequest from "home/profile/facebook/sendRequest";
import BulkMessage from "home/sendBulkMessage/sendBulkMessage";
import TargetedFriendRequest from "home/profile/facebook/targetedFriendRequest";
import SendBulkMessage from "home/profile/facebook/sendBulkMessage";
import ForgotPassword from "_components/forgotPassword/forgotPassword";
import ResetPassword from "_components/forgotPassword/resetPassword";
import Notification from "home/common/Notification";
import Search from "home/common/Search";

export { App };

function App() {
    history.navigate = useNavigate();
    history.location = useLocation();

    return (
        <div className="container-fluid">
            <NavbarComponent />
            <div className="row section">
                <Sidebar />
                <Routes>
                    <Route
                        path="/"
                        element={
                            <PrivateRoute>
                                <Home />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/tags"
                        element={
                            <PrivateRoute>
                                <Tags />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/template"
                        element={
                            <PrivateRoute>
                                <Template />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/friendrequest"
                        element={
                            <PrivateRoute>
                                <FriendRequest />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/profile"
                        element={
                            <PrivateRoute>
                                <Profile />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/userdashboard"
                        element={
                            <PrivateRoute>
                                <UserDashboard />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/change-password"
                        element={
                            <PrivateRoute>
                                <ChangePassword />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/tagsdata"
                        element={
                            <PrivateRoute>
                                <TagsData />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/sendrequest"
                        element={
                            <PrivateRoute>
                                <SendRequest />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/BulkMessage"
                        element={
                            <PrivateRoute>
                                <BulkMessage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/targetfriendrequest"
                        element={
                            <PrivateRoute>
                                <TargetedFriendRequest />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/bulkmessagesentdata"
                        element={
                            <PrivateRoute>
                                <SendBulkMessage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/notification"
                        element={
                            <PrivateRoute>
                                <Notification/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/search/:query"
                        element={
                            <PrivateRoute>
                                <Search/>
                            </PrivateRoute>
                        }
                    />
                    <Route path="/login" element={<Login />} />
                    <Route path="/forgot" element={<ForgotPassword />} />
                    <Route exact path="/reset/:token" element={<ResetPassword />} />
                    <Route
                        exact
                        path="/resetPassword/:token"
                        element={<ResetPassword />}
                    />
                </Routes>
            </div>
        </div>
    );
}