import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import Dropdown from "react-bootstrap/Dropdown";
import { SEARCH_CS_ITEMS, REACT_APP_URL, IMAGE_URL } from "_helpers/api";
import axios from "axios";

export default function Search() {
  const { query } = useParams();
  const { user: authUser, primary: primaryid } = useSelector((x) => x.auth);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchTags, setSearchTags] = useState([]);
  const [searchTaggedUsers, setSearchTaggedUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setSearchQuery(query || '');

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    const data = {
      search_terms: query,
      fb_account_id: primaryid,
    };
    axios.post(`${SEARCH_CS_ITEMS}`, data, {
      headers: headers,
    }).then((response) => {
      let finaldata = response.data;
      setSearchTags(finaldata.data.tags);
      setSearchTaggedUsers(finaldata.data.tagged_user);
    }).catch((error) => {
      console.log(error);
    });
  }, [query, authUser, primaryid]);

  const viewInTag = (tag) => {
    const extractedTagIds = tag.match(/\d+/g);
    const tagIds = extractedTagIds ? extractedTagIds.join(',') : '';
    navigate(`/tags?veiw_in_tag=${tagIds}`);
  };

  return (
    <div className="col-md-10 right_side">
      <div className="row">
        <div className="col-md-5 search-content-area">
          <h3>Search result for:</h3>
          <p className="searched-query-string">{searchQuery}</p>
          {searchTaggedUsers && searchTaggedUsers.length > 0 && (
            <div className="searched-contacts">
              <div className="header-search-area">
                <h4>Contact</h4>
              </div>
              <div className="all-contacts-in-this-tag">
                {searchTaggedUsers.map((searchTaggedUser) => (
                  <div className="row contact_list_item" key={searchTaggedUser.id}>
                    <div className="col-9 contact_name p-0">
                      <div className="d-flex align-items-center">
                        {searchTaggedUser.profile_pic === "" && searchTaggedUser.user_image === null? (
                          <img
                            src={`${REACT_APP_URL}assets/images/empty.png`}
                            className="profile-image"
                            alt={searchTaggedUser.fb_name}
                          />
                        ) : (
                          <img
                            src={searchTaggedUser.user_image != null?IMAGE_URL+searchTaggedUser.user_image:searchTaggedUser.profile_pic}
                            className="profile-image"
                            alt={searchTaggedUser.fb_name}
                            onError={(e) => {
                              e.target.src = REACT_APP_URL + "assets/images/empty.png";
                            }}
                          />
                        )}
                        <h4>{searchTaggedUser.fb_name}</h4>
                      </div>
                    </div>
                    <div className="col-3 text-end p-0">
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic-search" className="dropdown_arrow_toggle">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M9.3335 5.33203C9.8835 5.33203 10.3335 4.88203 10.3335 4.33203C10.3335 3.78203 9.8835 3.33203 9.3335 3.33203C8.7835 3.33203 8.3335 3.78203 8.3335 4.33203C8.3335 4.88203 8.7835 5.33203 9.3335 5.33203ZM9.3335 7.33203C8.7835 7.33203 8.3335 7.78203 8.3335 8.33203C8.3335 8.88203 8.7835 9.33203 9.3335 9.33203C9.8835 9.33203 10.3335 8.88203 10.3335 8.33203C10.3335 7.78203 9.8835 7.33203 9.3335 7.33203ZM8.3335 12.332C8.3335 11.782 8.7835 11.332 9.3335 11.332C9.8835 11.332 10.3335 11.782 10.3335 12.332C10.3335 12.882 9.8835 13.332 9.3335 13.332C8.7835 13.332 8.3335 12.882 8.3335 12.332Z" fill="#747B84" /></svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => viewInTag(searchTaggedUser.tag_id)}>View in tag</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {searchTags && searchTags.length > 0 && (
            <div className="searched-tags">
              <div className="header-search-area">
                <h4>Tag</h4>
              </div>
              <div className="searched-tags-area">
                <ul>
                  {searchTags.map((searchTag) => (
                    <li key={searchTag.id} onClick={() => navigate(`/tags?veiw_in_tag=${searchTag.id}`)}>{searchTag.name} ({searchTag.users})</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}