import Close from "@mui/icons-material/Close";
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { GET_NOTIFICATIONS_FOR_USERS, DELETE_NOTIFICATION, IMAGE_URL, REACT_APP_URL } from "_helpers/api";
import axios from "axios";
import moment from "moment";

export default function Notification() {
  const { user: authUser, primary: primaryid } = useSelector((x) => x.auth);
  const [notifications, setNotifications] = useState([]);


  const handleRemoveNotification = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    axios.get(`${DELETE_NOTIFICATION}/${id}`, { headers: headers });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = {
            fb_account_id: primaryid,
        };
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authUser.token}`,
        };
        // Make the GET request using Axios
        const response = await axios.post(`${GET_NOTIFICATIONS_FOR_USERS}`, data, { headers: headers });
        // Access the data property of the response object
        setNotifications(response.data.notifications);
      } catch (error) {
        console.log(error.message || 'An error occurred');
      }
    };
    // Call the async function
    fetchData();
  }, [authUser.token, primaryid]);

  // Function to render notifications based on type
  const renderNotification = (notification) => {
    let daysDifference = moment().diff(moment(new Date(notification.created_at)), 'days');
    if (daysDifference > 2) {
      let message = notification.message.replace(/just\s/gi, '');
      notification.message = message.charAt(0).toUpperCase() + message.slice(1);
    }

    switch (notification.notification_type) {
      case 'tag_addition':
        let [notificationMessage, userTag] = notification.message.split(":");
        let json_data = JSON.parse(notification.json_data);
        return (
          <p className="notif-desc">
            <span className="notif-span-parent">
              {notificationMessage}: <Link to={`/tags?veiw_in_tag=${json_data.tag_id}`}>{userTag.trim()}</Link>
            </span>
            <time>{moment(new Date(notification.created_at)).format("MMMM D, Y")}</time>
          </p>
        );
      case 'tag_assignment':
        let [notificationMessage_1] = notification.message.split(":");
        let json_data_1 = JSON.parse(notification.json_data);
        let formattedString_1 = formatTagString(json_data_1.tag_names, json_data_1.tag_ids);
        let [nms1, nms2] = notificationMessage_1.split(",");
        let [nt1, nt2, ...name] = nms1.split(" ");
        let initialString = [nt1, nt2].join(" ");
        let userName = [...name].join(" ");
        return (
          <p className="notif-desc">
            <span className="notif-span-parent">
              {initialString} <Link to={`/tags?veiw_in_tag=${json_data_1.tag_ids}`}>{userName}</Link>, {nms2}: <span dangerouslySetInnerHTML={{ __html: formattedString_1 }}></span>
            </span>
            <time>{moment(new Date(notification.created_at)).format("MMMM D, Y")}</time>
          </p>
        );
      case 'note_addition':
        let [notificationMessage_2, userTag_2] = notification.message.split(":");
        let note_description = userTag_2.length > 100 ? userTag_2.substring(0, 97) + '...' : userTag_2;
        let json_data_2 = JSON.parse(notification.json_data);
        let tagString = json_data_2.tag_ids.match(/\d+/g).map(function (item) {
          return parseInt(item);
        }).join(",");
        return (
          <p className="notif-desc">
            <span className="notif-span-parent">
              {notificationMessage_2}: <b className="nanchor">{note_description}</b> for <Link to={`/tags?veiw_in_tag=${tagString}`}>{json_data_2.fb_name}</Link>
            </span>
            <time>{moment(new Date(notification.created_at)).format("MMMM D, Y")}</time>
          </p>
        );
      case 'reminder_addition':
        let [notificationMessage_3, userTag_3] = notification.message.split(":");
        let json_data_3 = JSON.parse(notification.json_data);
        return (
          <p className="notif-desc">
            <span className="notif-span-parent">
              {notificationMessage_3}: <b className="nanchor">{userTag_3}</b> - <b>{'['}{moment.unix(json_data_3.reminder_time).format('h:mm a')} {moment.unix(json_data_3.reminder_time).format('D/M/YYYY')}{']'}</b>
            </span>
            <time>{moment(new Date(notification.created_at)).format("MMMM D, Y")}</time>
          </p>
        );
      case 'multiple_tag_assignment':
        let [notificationMessage_4] = notification.message.split(":");
        let json_data_4 = JSON.parse(notification.json_data);
        let formattedString_4 = formatTagString(json_data_4.tag_names, json_data_4.tag_ids);
        return (
          <p className="notif-desc">
            <span className="notif-span-parent">
              {notificationMessage_4}: <span dangerouslySetInnerHTML={{ __html: formattedString_4 }}></span>
            </span>
            <time>{moment(new Date(notification.created_at)).format("MMMM D, Y")}</time>
          </p>
        );
      default:
        return null;
    }
  };

  // Function to render notifications based on type
  const renderNotificationImage = (notification) => {
    switch (notification.notification_type) {
      case 'tag_addition':
        let [, uTag] = notification.message.split(":");
        let initials = uTag.split(" ").map(word => word.charAt(0)).join("").toUpperCase();
        return (
          <span className="notif-uicon" alt={uTag}>{initials}</span>
        );
      case 'tag_assignment':
        let json_data_5 = JSON.parse(notification.json_data);
        let user_image_5 = json_data_5.user_image ? `${IMAGE_URL}${json_data_5.user_image}` : json_data_5.profile_pic;
        return (
          <img className="notif-uicon" alt={json_data_5.fb_name} src={user_image_5} onError={(e) => {
            e.target.src = REACT_APP_URL + "assets/images/empty.png";
          }} />
        );
      case 'note_addition':
        let json_data_6 = JSON.parse(notification.json_data);
        let user_image_6 = json_data_6.user_image ? `${IMAGE_URL}${json_data_6.user_image}` : json_data_6.profile_pic;
        return (
          <img className="notif-uicon" alt={json_data_6.fb_name} src={user_image_6} onError={(e) => {
            e.target.src = REACT_APP_URL + "assets/images/empty.png";
          }} />
        );
      default:
        return (
          <img className="notif-uicon" src={authUser.image != null ? `${IMAGE_URL}images/${authUser.image}` : `${REACT_APP_URL}assets/images/profile-2.png`} alt={authUser.name} />
        );
    }
  };
  //Function to format tag names into a string
  const formatTagString = (tagNames, tagIds) => {
    tagNames = tagNames.map(function (tagName, i) {
      return "<a href='/tags?veiw_in_tag=" + tagIds[i] + "'>" + tagName + "</a>";
    });
    if (tagNames.length > 1) {
      const lastTag = tagNames.pop();
      return (`${tagNames.join(', ')} and ${lastTag}`);
    } else {
      return (tagNames.join(', '));
    }
  };

  return (
    <div className="col-md-10 right_side">
      <div className="row">
        <div className="col-md-7 notif-content-area">
          {notifications.length === 0 ? (
            <p className="empty-notif">No notifications to display.</p>
          ) : (
            <ul>
              {notifications.map((notification) => (
                <li key={notification.id}>
                  {renderNotificationImage(notification)}
                  {renderNotification(notification)}
                  <span className="notif-readme-close" onClick={() => handleRemoveNotification(notification.id)}>
                    <Close style={{ width: '18px', height: '18px' }} />
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}
