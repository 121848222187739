import React, { useState } from "react";
import { useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { SAVE_TAG } from "_helpers/api";
import { toast } from "react-toastify";
import axios from "axios";

export default function SaveTagComponent(props) {
    const [show, setShow] = useState(false);
    const [selectedinput, setSelectedinput] = useState("");
    const { user: authUser } = useSelector((x) => x.auth);

    const randomColor = [
        "primary",
        "secondary",
        "success",
        "danger",
        "warning",
        "info",
        "dark",
    ];
    const [randomNumber, setRandomNumber] = useState(0);
    const [validated, setValidated] = useState(false);
    const [errormsg, setErrorMsg] = useState();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleValue = (e) => {
        setSelectedinput(e.target.value);
    };

    const generateRandomNumber = (e) => {
        const randomNumber = Math.floor(Math.random() * randomColor.length);
        setRandomNumber(randomNumber);
    };
    const saveTag = (e) => {
        generateRandomNumber();
        if (selectedinput === "") {
            setValidated(true);
        } else {
            setValidated(false);
            saveApi();
        }
    };

    const saveApi = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        const data = {
            name: selectedinput,
            class: randomColor[randomNumber],
            custom_color: "",
        };
        axios
            .post(SAVE_TAG, data, {
                headers: headers,
            })
            .then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    props.tagdata(response.data.newtag);
                    setErrorMsg();
                    handleClose();
                } else {
                    setErrorMsg(response.data.message);
                }
            })
            .catch((error) => { });
    };

    return (
        <>
            <div className="add_tag button_global" onClick={handleShow}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M9.90359 4.80688C9.74929 4.80671 9.60126 4.86793 9.49215 4.97704C9.38304 5.08615 9.32182 5.23418 9.322 5.38848L9.31787 9.4184L5.28383 9.4184C5.12953 9.41823 4.98149 9.47944 4.87239 9.58855C4.76328 9.69766 4.70206 9.84569 4.70223 9.99999C4.70223 10.3217 4.96209 10.5816 5.28383 10.5816L9.31787 10.5816L9.31787 14.6156C9.31787 14.9374 9.57773 15.1972 9.89947 15.1972C10.2212 15.1972 10.4811 14.9374 10.4811 14.6156L10.4811 10.5816L14.5151 10.5816C14.8368 10.5816 15.0967 10.3217 15.0967 9.99999C15.0967 9.67826 14.8368 9.4184 14.5151 9.4184L10.4811 9.4184L10.4811 5.38435C10.4811 5.07087 10.2171 4.80688 9.90359 4.80688Z" fill="white" /></svg>
                add new tag
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton style={{ border: 0 }}>
                    <Modal.Title>ADD TAG</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Tag Name</Form.Label>
                            <Form.Control
                                type="text"
                                className={
                                    validated ? "redBordervalidationerror" : "originalClass"
                                }
                                placeholder="Enter Tag Name"
                                autoFocus
                                onChange={(e) => handleValue(e)}
                            />
                            {errormsg !== "" ? (
                                <h6 style={{ color: "red" }}>{errormsg}</h6>
                            ) : (
                                ""
                            )}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{ border: 0 }}>
                    <div
                        className="add_tag button_global"
                        onClick={(e) => saveTag(e)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M9.90359 4.80688C9.74929 4.80671 9.60126 4.86793 9.49215 4.97704C9.38304 5.08615 9.32182 5.23418 9.322 5.38848L9.31787 9.4184L5.28383 9.4184C5.12953 9.41823 4.98149 9.47944 4.87239 9.58855C4.76328 9.69766 4.70206 9.84569 4.70223 9.99999C4.70223 10.3217 4.96209 10.5816 5.28383 10.5816L9.31787 10.5816L9.31787 14.6156C9.31787 14.9374 9.57773 15.1972 9.89947 15.1972C10.2212 15.1972 10.4811 14.9374 10.4811 14.6156L10.4811 10.5816L14.5151 10.5816C14.8368 10.5816 15.0967 10.3217 15.0967 9.99999C15.0967 9.67826 14.8368 9.4184 14.5151 9.4184L10.4811 9.4184L10.4811 5.38435C10.4811 5.07087 10.2171 4.80688 9.90359 4.80688Z" fill="white" /></svg>
                        Add
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}