import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Card, Col, Button } from "react-bootstrap";
import { CHATSILO_FACEBOOK_TAB } from "_helpers/api";
import { history } from '_helpers';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

import Loader from "_components/loader";
export { FacebookTab };

function FacebookTab(props) {
    const { user: authUser } = useSelector((x) => x.auth);
    let [data, updateData] = useState({
        tag: 0,
        template: 0,
        bulkmessage: 0,
        friendrequest: 0,
        taggedfriendrequest: 0,
    });
    let [status, updateStatus] = useState(true);

    useEffect(() => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        const data = {
            fb_account_id: props.fbaccount
        };
        axios
            .post(`${CHATSILO_FACEBOOK_TAB}`, data, {
                headers: headers,
            })
            .then((response) => {
                let finaldata = response.data.data;
                updateData({
                    tag: finaldata.tags,
                    template: finaldata.template,
                    bulkmessage: finaldata.bulkmessagesend,
                    friendrequest: finaldata.sendrequest,
                    taggedfriendrequest: finaldata.targetedfriendrequest,
                });
                updateStatus(false);
            })
            .catch((error) => {
                updateStatus(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // only show nav when logged in
    if (!authUser) return null;
    
    return (
        <>
            {status === true ? (
                <Loader />
            ) : (
                <Row>
                    <Col md={3}>
                        <Card className="columns border-0">
                            <Row>
                                <Col md={12}>
                                    <h4>
                                        <HomeOutlinedIcon className="infobox-icons home" /> Tags
                                    </h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="d-flex justify-content-between">
                                    <h3>{data.tag}</h3>
                                    <Button variant="light"
                                        onClick={(e) => history.navigate('tagsdata', { state: { name: 'chatsilo', account: props.fbaccount } })}
                                    >
                                        <KeyboardArrowRightOutlinedIcon /> 
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="columns border-0">
                            <Row>
                                <Col md={12}>
                                    <h4>
                                        <ShoppingBasketOutlinedIcon className="infobox-icons basket" /> Templates
                                    </h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="d-flex justify-content-between">
                                    <h3>{data.template}</h3>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="columns border-0">
                            <Row>
                                <Col md={12}>
                                    <h4>
                                        <Inventory2OutlinedIcon className="infobox-icons inventory" /> Bulk Messages
                                    </h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="d-flex justify-content-between">
                                    <h3>{data.bulkmessage}</h3>
                                    <Button variant="light"
                                        onClick={(e) => history.navigate('bulkmessagesentdata', { state: { name: 'chatsilo', account: props.fbaccount } })}
                                    >
                                        <KeyboardArrowRightOutlinedIcon />
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col> 
                    <Col md={3}>
                        <Card className="columns border-0">
                            <Row>
                                <Col md={12}>
                                    <h4>
                                        <VolunteerActivismOutlinedIcon className="infobox-icons volunteer" /> Friend request
                                    </h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="d-flex justify-content-between">
                                    <h3>{data.friendrequest}</h3>
                                    <Button variant="light"
                                        onClick={(e) => history.navigate('sendrequest', { state: { name: 'chatsilo', account: props.fbaccount } })}
                                    >
                                        <KeyboardArrowRightOutlinedIcon />
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    {/* <Col md={3}>
                        <Card className="columns border-0">
                            <Row>
                                <Col md={12}>
                                    <h4>
                                        <VolunteerActivismOutlinedIcon className="infobox-icons volunteer" />Targeted Friend request
                                    </h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="d-flex justify-content-between">
                                    <h3>{data.taggedfriendrequest}</h3>
                                    <Button variant="light"
                                        onClick={(e) => history.navigate('targetfriendrequest', { state: { name: 'chatsilo', account: props.fbaccount } })}
                                    >
                                        <KeyboardArrowRightOutlinedIcon />
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col> */}
                </Row>
            )}
        </>
    );
}
