import React, { useState } from "react";
import { CHANGE_PASSWORD } from "_helpers/api";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

export default function ChangePassword() {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [currentPasswordError, setCurrentPasswordError] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const authUser = useSelector((x) => x.auth.user);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "currentPassword") {
            setCurrentPassword(value);
            if (currentPasswordError && value.length > 0) {
                setCurrentPasswordError("");
            }
        } else if (name === "newPassword") {
            setNewPassword(value);
            if (newPasswordError && value.length > 0) {
                setNewPasswordError("");
            }
        } else if (name === "confirmPassword") {
            setConfirmPassword(value);
            if (confirmPasswordError && value.length > 0) {
                setConfirmPasswordError("");
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your validation logic here
        let isValid = true;

        if (currentPassword === "") {
            setCurrentPasswordError("Please enter your current password.");
            isValid = false;
        }

        if (newPassword === "") {
            setNewPasswordError("Please enter a new password.");
            isValid = false;
        } else if (newPassword.length < 6) {
            setNewPasswordError("Password must be at least 6 characters long.");
            isValid = false;
        }

        if (confirmPassword === "") {
            setConfirmPasswordError("Please confirm your new password.");
            isValid = false;
        } else if (confirmPassword !== newPassword) {
            setConfirmPasswordError("Passwords do not match.");
            isValid = false;
        }

        if (isValid) {
            axios.post(CHANGE_PASSWORD, {
                current_password: currentPassword,
                new_password: newPassword,
                confirm_password: confirmPassword
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authUser.token}`
                }
            }).then(response => {
                // Handle success
                if (response.data.status === 200) {
                    setCurrentPassword("");
                    setNewPassword("");
                    setConfirmPassword("");
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        onClose: () => {
                            navigate("/");
                        }
                    });
                }
            }).catch(error => {
                // Handle error
                console.error("Password change failed:", error.response.data.message);
                setCurrentPasswordError(error.response.data.message);
            });
        }
    };

    return (
        <div className="col-md-10 right_side">
            <ToastContainer/>
            <div className="row head_name">
                <div className="col-md-3 left_col">
                    <h3>Change Password</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5 cp-content-area">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group mt-2">
                            <label htmlFor="currentPassword">Current Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="currentPassword"
                                name="currentPassword"
                                value={currentPassword}
                                onChange={handleChange}
                            />
                            <small className="text-danger">{currentPasswordError}</small>
                        </div>
                        <div className="form-group mt-2">
                            <label htmlFor="newPassword">New Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="newPassword"
                                name="newPassword"
                                value={newPassword}
                                onChange={handleChange}
                            />
                            <small className="text-danger">{newPasswordError}</small>
                        </div>
                        <div className="form-group mt-2">
                            <label htmlFor="confirmPassword">Confirm New Password</label>
                            <input
                                type="text"
                                className="form-control"
                                id="confirmPassword"
                                name="confirmPassword"
                                value={confirmPassword}
                                onChange={handleChange}
                            />
                            <small className="text-danger">{confirmPasswordError}</small>
                        </div>
                        <div className="form-group text-right">
                            <button type="submit" className="btn btn-danger mt-4">Change Password</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
