import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { CHATSILO_BULK_MESSAGE_SENT_DATA } from "_helpers/api";
import Loader from "_components/loader";
import moment from "moment";
import { history } from '_helpers';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

export default function SendBulkMessage() {
    const { state } = useLocation();
    const { name, account } = state;
    const [status, updateStatus] = useState(true);
    const [tagarray, updateTagarray] = useState({});
    const { user: authUser } = useSelector((x) => x.auth);

    useEffect(() => {
        if (name === "chatsilo") {
            fecthchatsilotagdata();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fecthchatsilotagdata = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        const data = {
            type: 0,
            fb_account_id: account
        };
        axios
            .post(`${CHATSILO_BULK_MESSAGE_SENT_DATA}`, data, {
                headers: headers,
            })
            .then((response) => {
                let finaldata = response.data.data;
                updateTagarray(finaldata);
                updateStatus(false);
            })
            .catch((error) => {
                updateStatus(false);
            });
    }; 

    return (
        <div className="col-md-10">
            <div className="row">
                <div className="col-md-12 right_side">
                    <div className="row head_name">
                        <div className="col-md-5 left_col"> 
                            <h3 className="d-flex align-items-center">
                                <span>
                                    <ArrowBackOutlinedIcon style={{marginRight:"10px", cursor:"pointer"}} onClick={(e) => history.navigate('userdashboard', { state: { name: account } })} />
                                    Bulk messages ({tagarray.length})
                                </span>
                            </h3>
                        </div>
                    </div>
                    {status === true ? (
                        <Loader />
                    ) : (
                        <>
                            <div className="row tags_sec" style={{ overflowY: "scroll" }}>
                                {tagarray.length === 0 ? (
                                    <h4 style={{ textAlign: "center" }}>No data found</h4>
                                ) : (
                                    <>
                                        <div className="row bulk_message_cols account_page">
                                            {tagarray.map((tagdata, i) => (
                                                <div className="message_sec" key={"send-bulk-message-key"+i}>
                                                    <p>{tagdata.message}</p>
                                                    <span>{moment(new Date(tagdata.created_at)).format(
                                                        "DD/MM/YYYY"
                                                    )}</span>
                                                    <span>{tagdata.sent} sent</span>
                                                    <span>{tagdata.error} failed</span>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}