import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import DataTable from 'react-data-table-component';
import axios from "axios";
import { SENT_BULK_MESSAGES, SENT_BULK_MESSAGE_DELETE } from "_helpers/api";
import Loader from "_components/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import Select from "react-dropdown-select";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

export default function BulkMessage() {
    const { user: authUser, primary } = useSelector((x) => x.auth);
    const [requestdata, updateRequestData] = useState([]);
    const [newcopyrequestdata, updatenewcopyRequestData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [inputvaluenew, setinputvaluenew] = useState('');
    const [statusToshow, setStatusToshow] = useState('');
    const [dateFilter, setDateFilter] = useState('');


    const [arrayid, setarrayid] = useState([]);
    const [pending, setPending] = React.useState(true);

    useEffect(() => {
        fecthdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fecthdata = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        const data = {
            fb_account_id: primary,
        };
        axios
            .post(`${SENT_BULK_MESSAGES}`, data, {
                headers: headers,
            })
            .then((response) => {
                updateRequestData(response.data.data);
                updatenewcopyRequestData(response.data.data);
                setPending(false);
            })
            .catch((error) => {
                updateRequestData([]);
                setPending(false);
            });
    };
    const columns = [
        {
            name: 'Name',
            selector: (row) => <p>{row['request_fb_name']}</p>,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => <p className={row.status === 0 ? 'not_success_message' : 'success_message'}>{row.status === 0 ? 'Failed' : 'sent'}</p>,
            sortable: true,
        },
        {
            name: 'Message Sent',
            selector: (row) => <p>{row['message']}</p>,
            sortable: true,
        },
        {
            name: 'Keyword',
            selector: (row) => <p>{row['keyword']}</p>,
        },
        {
            name: 'Date',
            selector: (row) => <p> {moment(new Date(row.created_at)).format(
                "DD/MM/YYYY"
            )}</p>,
        },
    ];

    const handleRowSelected = useCallback(state => {
        var array = [];
        setSelectedRows(state.selectedRows);
        state.selectedRows.map(r => {
            array.push(r.id)
            return null;
        })
        setarrayid(array)
    }, []);

    const contextActions = useMemo(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestdata, selectedRows]);

    const searchinput = (data) => {
        setinputvaluenew(data.target.value)
    };
    const referesh = () => {
        setPending(true)
        fecthdata();
    }

    const handleClearRows = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#572F5B",
            cancelButtonColor: "#D9D9D9",
            confirmButtonText: "Yes,delete",
        }).then((result) => {
            if (result.isConfirmed) {
                // updateTagarray((current) => current.filter((array) => array.id !== id));
                deleteApi(arrayid);
                Swal.fire("Deleted!", "Your file has been deleted.", "success");
            }
        });
    }
    const deleteApi = (arrayid) => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        const data = {
            ids: arrayid,
        };
        axios
            .post(SENT_BULK_MESSAGE_DELETE, data, {
                headers: headers,
            })
            .then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setPending(true)
                    fecthdata();
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((error) => { });
    };

    const options = [
        {
            label: "Any Status",
            value: "any-status",
        },
        {
            label: "Sent Only",
            value: "sent-only",
        },
        {
            label: "Failed Only",
            value: "failed-only",
        },
    ];



    const options2 = [
        {
            label: "All Sent Friend Request",
            value: "all",
        },
        {
            label: "Older then 2 weeks",
            value: "2_weeks",
        },
        {
            label: "Older then 1 month",
            value: "1_month",
        },
        {
            label: "Older then 2 month",
            value: "2_month",
        },
        {
            label: "Older then 6 month",
            value: "6_month",
        },
        {
            label: "1 Year",
            value: "1_year",
        },
    ];

    const sortmessage = (data) => {
        setStatusToshow(data[0].value);
    };

    const sortmessagedatefilter = (data) => {
        setDateFilter(data[0].value);
    };

    useEffect(() => {
        let tempdata = newcopyrequestdata;
        let statusFilterValue = "";

        if (statusToshow === "sent-only") {
            statusFilterValue = 1;
        } else if (statusToshow === "failed-only") {
            statusFilterValue = 0;
        }

        let date = null;
        if (dateFilter === "2_weeks") {
            date = new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 14));
        } else if (dateFilter === "1_month") {
            date = new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 30));
        } else if (dateFilter === "2_month") {
            date = new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 60));
        } else if (dateFilter === "6_month") {
            date = new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 180));
        } else if (dateFilter === "1_year") {
            date = new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 365));
        }

        tempdata = tempdata.filter(item => {
            const isRequestFbNameMatch = item?.request_fb_name?.toLowerCase()?.includes(inputvaluenew.toLowerCase());
            const isMessageMatch = item?.message?.toLowerCase()?.includes(inputvaluenew.toLowerCase());

            let isStatusFilterMatch = true;
            if (statusFilterValue !== "") {
                isStatusFilterMatch = (item?.status === statusFilterValue);
            }

            let isDateFilterMatch = true;
            if (date !== null) {
                isDateFilterMatch = (new Date(item?.created_at).getTime() <= date.getTime());
            }

            return ((isRequestFbNameMatch || isMessageMatch) && isStatusFilterMatch) && isDateFilterMatch;
        });
        updateRequestData(tempdata);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputvaluenew, statusToshow, dateFilter, requestdata])

    return (

        <div className="col-md-10 right_side">
            <ToastContainer />
            <div className="row head_name">
                <div className="col-md-3 left_col">
                    <h3>Sent Bulk messages</h3>
                </div>
                <div className="col-md-9 right_col align-items-center justify-content-center d-flex">
                    <div className="d-flex justify-content-end">
                        <div className="head_top search_field">
                            <div className="input-group">
                                <input type="text" className="form-control" value={inputvaluenew} onChange={(e) => searchinput(e)} placeholder="Search bulk messages" />
                                <button className="tag_search_button" >
                                    <SearchOutlinedIcon />
                                </button>
                            </div>
                        </div>
                        <div className="head_top">
                            <Select
                                className="dropdown_tag"
                                options={options}
                                onChange={(values) => sortmessage(values)}
                                placeholder="Any status"
                            />
                        </div>
                        <div className="head_top">
                            <Select
                                className="dropdown_tag"
                                options={options2}
                                onChange={(values) => sortmessagedatefilter(values)}
                                placeholder="Filter"
                            />
                        </div>
                        <div className="actions">
                            <div className="a-btn-tag" onClick={() => { referesh() }}><img src="assets/images/refresh.png" alt="" />Refresh</div>
                        </div>
                        <div className="actions action_last_col">
                            <div className="a-btn-tag" onClick={arrayid.length > 0 ? handleClearRows : null}><img src="assets/images/delete.png" alt="" />Delete</div>
                        </div>
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={requestdata}
                pagination
                highlightOnHover
                selectableRows
                contextActions={contextActions}
                progressComponent={<Loader />}
                progressPending={pending}
                onSelectedRowsChange={handleRowSelected}
            />
        </div>
    );
}
