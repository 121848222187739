import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Nav, Navbar, Form, Button, Badge } from "react-bootstrap";
import { authActions } from "_store";
import { GET_NOTIFICATIONS_FOR_USERS, MARK_AS_READ_NOTIFICATION, MARK_AS_ALL_READ_NOTIFICATION, IMAGE_URL, REACT_APP_URL } from "_helpers/api";
import SearchIcon from '@mui/icons-material/Search';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import CircleIcon from '@mui/icons-material/Circle';
import axios from "axios";
import moment from "moment";

export function NavbarComponent() {
    const { user: authUser, primary } = useSelector((x) => x.auth);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());
    const navigate = useNavigate();
    const location = useLocation();
    const [showNotification, setShowNotification] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [notificationCount, setNotificationCount] = useState(0);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                if (!authUser || !authUser.token) {
                    return; // Exit early if authUser or token is null
                }
                const data = {
                    fb_account_id: primary,
                };
                const headers = {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authUser.token}`,
                };
                // Make the GET request using Axios
                const response = await axios.post(`${GET_NOTIFICATIONS_FOR_USERS}`, data, { headers: headers });
                // Access the data property of the response object
                setNotificationCount(response.data.unread_notifications.length);
                setNotifications(response.data.unread_notifications);
            } catch (error) {
                console.log(error.message || 'An error occurred');
            }
        };
    
        fetchNotifications();
        // Set up polling interval (e.g., every 1 minute)
        const pollingInterval = setInterval(fetchNotifications, 60000);
    
        // Clean up the interval on component unmount
        return () => clearInterval(pollingInterval);
        // eslint-disable-next-line
    }, [authUser]); // Include authUser in the dependency array
    

    useEffect(() => {
        // Handle logic when the route changes
        setShowNotification(false);
    }, [location.pathname]);

    const handleNotificationClick = () => {
        // Toggle the notification visibility
        setShowNotification(!showNotification);
    };

    const PreviousNotifications = () => {
        // Toggle the notification visibility
        navigate("/notification");
    };

    const handleSearch = () => {
        // Check if the searchQuery has at least three characters
        if (searchQuery.length >= 3) {
            // Redirect to the search page with the searchQuery as a parameter
            navigate(`/search/${searchQuery}`);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSearch();
    };

    const markAsRead = (nid) => {
        try {
            setNotifications((prevNotifications) =>
                prevNotifications.filter((notification) => notification.id !== nid)
            );
            setNotificationCount(notificationCount - 1);
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authUser.token}`,
            };
            axios.get(`${MARK_AS_READ_NOTIFICATION}/${nid}`, { headers: headers });
        } catch (error) {
            console.log(error.message || 'An error occurred');
        }
    };

    const markAllAsRead = () => {
        try {
            setNotifications([]);
            setNotificationCount(0);
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authUser.token}`,
            };
            axios.get(`${MARK_AS_ALL_READ_NOTIFICATION}`, { headers: headers });
        } catch (error) {
            console.log(error.message || 'An error occurred');
        }
    };

    // Assuming you have a Notification component
    const Notification = () => (
        <div className="notification">
            <h2>New for you</h2>
            {notificationCount > 0 &&
                <button className="mark-all-read" onClick={() => markAllAsRead()}>Mark all read</button>
            }
            {notifications.length === 0 ? (
                <p className="empty-notif">No notifications to display.</p>
            ) : (
                <ul>
                    {notifications.map((notification) => (
                        <li key={notification.id} onClick={() => markAsRead(notification.id)}>
                            {renderNotificationImage(notification)}
                            {renderNotification(notification)}
                            <span className="notif-readme-dot-parent"><CircleIcon className="notif-readme-dot" /></span>
                        </li>
                    ))}
                </ul>
            )}
            {location.pathname.indexOf("notification") !== 1 &&
                <button className="previous-notifications" onClick={PreviousNotifications}>Previous notifications</button>
            }
        </div>
    );

    // Function to render notifications based on type
    const renderNotification = (notification) => {
        let daysDifference = moment().diff(moment(new Date(notification.created_at)), 'days');
        if (daysDifference > 2) {
            let message = notification.message.replace(/just\s/gi, '');
            notification.message = message.charAt(0).toUpperCase() + message.slice(1);
        }
        switch (notification.notification_type) {
            case 'tag_addition':
                let [notificationMessage, userTag] = notification.message.split(":");
                let json_data = JSON.parse(notification.json_data);
                return (
                    <p className="notif-desc">
                        <span className="notif-span-parent">
                            {notificationMessage}: <Link to={`/tags?veiw_in_tag=${json_data.tag_id}`}>{userTag.trim()}</Link>
                        </span>
                        <time>{moment(new Date(notification.created_at)).format("MMMM D, Y")}</time>
                    </p>
                );
            case 'tag_assignment':
                let [notificationMessage_1] = notification.message.split(":");
                let json_data_1 = JSON.parse(notification.json_data);
                let formattedString_1 = formatTagString(json_data_1.tag_names, json_data_1.tag_ids);
                let [nms1, nms2] = notificationMessage_1.split(",");
                let [nt1, nt2, ...name] = nms1.split(" ");
                let initialString = [nt1, nt2].join(" ");
                let userName = [...name].join(" ");
                return (
                    <p className="notif-desc">
                        <span className="notif-span-parent">
                            {initialString} <Link to={`/tags?veiw_in_tag=${json_data_1.tag_ids}`}>{userName}</Link>, {nms2}: <span dangerouslySetInnerHTML={{ __html: formattedString_1 }}></span>
                        </span>
                        <time>{moment(new Date(notification.created_at)).format("MMMM D, Y")}</time>
                    </p>
                );
            case 'note_addition':
                let [notificationMessage_2, userTag_2] = notification.message.split(":");
                let note_description = userTag_2.length > 100 ? userTag_2.substring(0, 97) + '...' : userTag_2;
                let json_data_2 = JSON.parse(notification.json_data);
                let tagString = json_data_2.tag_ids.match(/\d+/g).map(function (item) {
                    return parseInt(item);
                }).join(",");
                return (
                    <p className="notif-desc">
                        <span className="notif-span-parent">
                            {notificationMessage_2}: <b className="nanchor">{note_description}</b> for <Link to={`/tags?veiw_in_tag=${tagString}`}>{json_data_2.fb_name}</Link>
                        </span>
                        <time>{moment(new Date(notification.created_at)).format("MMMM D, Y")}</time>
                    </p>
                );
            case 'reminder_addition':
                let [notificationMessage_3, userTag_3] = notification.message.split(":");
                let json_data_3 = JSON.parse(notification.json_data);
                return (
                    <p className="notif-desc">
                        <span className="notif-span-parent">
                            {notificationMessage_3}: <b className="nanchor">{userTag_3}</b> - <b>{'['}{moment.unix(json_data_3.reminder_time).format('h:mm a')} {moment.unix(json_data_3.reminder_time).format('D/M/YYYY')}{']'}</b>
                        </span>
                        <time>{moment(new Date(notification.created_at)).format("MMMM D, Y")}</time>
                    </p>
                );
            case 'multiple_tag_assignment':
                let [notificationMessage_4] = notification.message.split(":");
                let json_data_4 = JSON.parse(notification.json_data);
                let formattedString_4 = formatTagString(json_data_4.tag_names, json_data_4.tag_ids);
                return (
                    <p className="notif-desc">
                        <span className="notif-span-parent">
                            {notificationMessage_4}: <span dangerouslySetInnerHTML={{ __html: formattedString_4 }}></span>
                        </span>
                        <time>{moment(new Date(notification.created_at)).format("MMMM D, Y")}</time>
                    </p>
                );
            default:
                return null;
        }
    };

    // Function to render notifications based on type
    const renderNotificationImage = (notification) => {
        switch (notification.notification_type) {
            case 'tag_addition':
                let [, uTag] = notification.message.split(":");
                let initials = uTag.split(" ").map(word => word.charAt(0)).join("").toUpperCase();
                return (
                    <span className="notif-uicon" alt={uTag}>{initials}</span>
                );
            case 'tag_assignment':
                let json_data_5 = JSON.parse(notification.json_data);
                return (
                    <img className="notif-uicon" alt={json_data_5.fb_name} src={json_data_5.profile_pic} onError={(e) => {
                        e.target.src = REACT_APP_URL + "assets/images/empty.png";
                    }} />
                );
            case 'note_addition':
                let json_data_6 = JSON.parse(notification.json_data);
                return (
                    <img className="notif-uicon" alt={json_data_6.fb_name} src={json_data_6.profile_pic} onError={(e) => {
                        e.target.src = REACT_APP_URL + "assets/images/empty.png";
                    }} />
                );
            default:
                return (
                    <img className="notif-uicon" src={authUser.image != null ? `${IMAGE_URL}images/${authUser.image}` : `${REACT_APP_URL}assets/images/profile-2.png`} alt={authUser.name} />
                );
        }
    };

    //Function to format tag names into a string
    const formatTagString = (tagNames, tagIds) => {
        tagNames = tagNames.map(function (tagName, i) {
            return "<a href='/tags?veiw_in_tag=" + tagIds[i] + "'>" + tagName + "</a>";
        });
        if (tagNames.length > 1) {
            const lastTag = tagNames.pop();
            return (`${tagNames.join(', ')} and ${lastTag}`);
        } else {
            return (tagNames.join(', '));
        }
    };

    const handleClear = (event) => {
        if(event.target.value === ""){
            navigate("/");
        }
    };

    if (!authUser) return null;
    return (
        <Row>
            <Navbar
                collapseOnSelect
                expand="lg"
                bg="white"
                variant="dark"
                className="header"
            >
                <Container fluid>
                    <Navbar.Brand href="/">
                        <img src={`${REACT_APP_URL}assets/images/logo-hd-web.png`} alt="Chatsilo" width="150px"/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="m-auto">
                            <Form className="d-flex" onSubmit={handleSubmit}>
                                <Form.Control
                                    type="search"
                                    placeholder="Search"
                                    className="form-control"
                                    aria-label="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    onBlur={handleSearch}
                                    onInput={handleClear} 
                                />
                                <Button variant="outline-light" style={{ borderRadius: "0px" }} className="input-group-text rounded-end" onClick={handleSearch}>
                                    <SearchIcon style={{ fill: "#94A2BC" }} />
                                </Button>
                            </Form>
                        </Nav>
                        <Nav className="navbar-nav-main">
                            <Nav.Link href="#" className="mt-2 position-relative mr-2">
                                <NotificationsNoneIcon className="notfi-icon-top" onClick={handleNotificationClick} /><Badge className="badge-style" bg="danger">{notificationCount}</Badge>
                            </Nav.Link>
                            {showNotification && (
                                <Notification />
                            )}
                            <div className="dropdown">
                                <img src={authUser.image != null ? `${IMAGE_URL}images/${authUser.image}` : "assets/images/profile-2.png"} className="dropbtn dropbtn-top-style user-image" alt="user profile" height={"40px"} width={"40px"} />
                                <div className="dropdown-content">
                                    <Link to="/profile">profile</Link>
                                    <Link to="/change-password">change password</Link>
                                    <Link onClick={logout}>logout</Link>
                                </div>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Row>
    );
}