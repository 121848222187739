import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
    GET_TAG_WITH_COUNTER,
    DELETE_TAG,
    UPDATE_TAG_COLOR_ND_NAME,
    EXPORT_TAG,
    UPDATE_TAG_ORDER,
} from "_helpers/api";
import Loader from "_components/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import SaveTagComponent from "./saveTag";
import ImportTag from "./importTag";
import ContactTag from "./tagContact";
import Select from "react-dropdown-select";
import { HexColorPicker } from "react-colorful";
import { ListManager } from "react-beautiful-dnd-grid";
import {
    Button,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
} from "react-bootstrap";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useLocation } from 'react-router-dom';

let defaultColors = {
    primary: "#0d6efd",
    secondary: "#6c757d",
    success: "#198754",
    danger: "#dc3545",
    warning: "#ffc107",
    info: "#0dcaf0",
    light: "#f8f9fa",
    dark: "#212529",
};
const options = [
    { label: "By A-Z", value: "A-Z" },
    { label: "By Z-A", value: "Z-A" },
    { label: "Oldest first", value: "oldest-first-asc" },
    { label: "Newest first", value: "newest-first-desc" },
    { label: "Custom", value: "custom" },
];

export default function Tags() {
    const primaryIds = useSelector((x) => x.auth.primary);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const veiwInTag = queryParams.get('veiw_in_tag');

    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);
    const [status, updateStatus] = useState(true);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editModalData, setEditModalData] = useState({});
    const { user: authUser } = useSelector((x) => x.auth);
    const { primary: primaryid } = useSelector((x) => x.auth);
    const [tagarray, updateTagarray] = useState([]);
    const [groupcontact, setGroupcontact] = useState({});
    const [maxItems, setMaxItems] = useState(calculateMaxItems());

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
        // eslint-disable-next-line
    }, []);

    function calculateMaxItems() {
        const windowWidth = window.innerWidth;

        const breakpoints = [2560, 1920, 1600, 1440, 1366, 1024, 991, 768];
        const maxItemsValues = [10, 7, 6, 5, 5, 4, 3, 2, 1];

        for (let i = 0; i < breakpoints.length; i++) {
            if (windowWidth >= breakpoints[i]) {
                return maxItemsValues[i];
            }
        }
        return 1;
    }

    function handleResize() {
        const newMaxItems = calculateMaxItems();
        setMaxItems(newMaxItems);
    }

    let [newcopytagarray, updateNewcopytagarray] = useState([]);

    useEffect(() => {
        fecthdata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [primaryIds]);

    const fecthdata = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        const data = {
            fb_account_id: primaryid,
        };
        axios
            .post(`${GET_TAG_WITH_COUNTER}`, data, {
                headers: headers,
            })
            .then((response) => {
                let finaldata = response.data.tag_data;
                if(veiwInTag !== null){
                    let veiwInTagArray = veiwInTag.split(",").map((item)=>parseInt(item));
                    let filterddata = finaldata.filter(function(item){
                        return veiwInTagArray.includes(item.id);
                    });
                    updateTagarray(filterddata);
                    updateNewcopytagarray(filterddata);
                    updateStatus(false);
                }else{
                    updateTagarray(finaldata);
                    updateNewcopytagarray(finaldata);
                    updateStatus(false);
                }
            })
            .catch((error) => {
                updateStatus(false);
            });
    };

    const deleteTag = (e, id, index) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#572F5B",
            cancelButtonColor: "#D9D9D9",
            confirmButtonText: "Yes,delete",
        }).then((result) => {
            if (result.isConfirmed) {
                updateTagarray((current) => current.filter((array) => array.id !== id));
                deleteApi(id);
                Swal.fire("Deleted!", "Your file has been deleted.", "success");
            }
        });
    };

    const deleteApi = (id) => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        
        axios
            .get(DELETE_TAG + "/" + id, {
                headers: headers,
            })
            .then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((error) => { });
    };

    const newtagdata = (data) => {
        var tempdata = tagarray;

        tempdata.push(data);
        updateTagarray(tempdata);
        forceUpdate();
        //
    };

    const exceltagdata = (data) => {
        fecthdata();
    };

    const sorttag = (data) => {
        var tempdata = tagarray;
        if (data[0].value === "A-Z") {
            tempdata.sort((a, b) => a.name.localeCompare(b.name));
        } else if (data[0].value === "Z-A") {
            tempdata.sort((a, b) => b.name.localeCompare(a.name));
        } else if (data[0].value === "oldest-first-asc") {
            tempdata.sort((a, b) => a.id - b.id);
        } else if (data[0].value === "newest-first-desc") {
            tempdata.sort((a, b) => b.id - a.id);
        } else if (data[0].value === "custom") {
            tempdata.sort((a, b) => a.order_num - b.order_num);
        } else {
            tempdata = tagarray;
        }
        updateTagarray(tempdata);
        forceUpdate();
    };

    const searchinput = (data) => {
        var input_value = data.target.value;
        if (input_value === "") {
            updateTagarray(newcopytagarray);
            forceUpdate();
        } else {
            var tempdata = newcopytagarray;
            const filteredArray = tempdata.filter((item) =>
                item.name.toLowerCase().includes(input_value.toLowerCase())
            );
            if (filteredArray.length === 0) {
                updateTagarray([]);
            } else {
                updateTagarray(filteredArray);
            }
            forceUpdate();
        }
    };

    const exportTag = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        axios({
            url: `${EXPORT_TAG}`,
            method: "Post",
            responseType: "blob",
            headers: headers, // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "file.csv");
            document.body.appendChild(link);
            link.click();
        });
    };

    const checkGroupcontact = (e, data) => {
        setGroupcontact(data);
        forceUpdate();
    };

    const updatepopupback = () => {
        fecthdata();
    };

    function sortList(list) {
        let newList = list
            .slice()
            .sort((first, second) => first.order_num - second.order_num);
        let newTagsData = newList.reduce((previousValue, currentValue) => {
            let temp = {
                tag_id: currentValue.id,
                order_num: previousValue.length + 1,
            };
            previousValue.push(temp);
            return previousValue;
        }, []);
        updateSortSettingInDB(newTagsData);
        return newList;
    }

    const updateSortSettingInDB = (sortedData) => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        axios
            .post(
                UPDATE_TAG_ORDER,
                { changedorder: sortedData },
                {
                    headers: headers,
                }
            )
            .then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    fecthdata();
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
                handleEditModalClose();
            })
            .catch((error) => {
                toast.error("Something went wrong, try again later", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                handleEditModalClose();
            });
    };

    const handleOnDragEnd = (sourceIndex, destinationIndex) => {
        if (destinationIndex === sourceIndex) {
            return;
        }
        let list = [...tagarray];

        if (destinationIndex === 0) {
            list[sourceIndex].order_num = list[0].order_num - 1;
            updateTagarray(sortList(list));
            sorttag([{ value: "custom" }]);
            return;
        }

        if (destinationIndex === list.length - 1) {
            list[sourceIndex].order_num = list[list.length - 1].order_num + 1;
            updateTagarray(sortList(list));
            sorttag([{ value: "custom" }]);
            return;
        }

        if (destinationIndex < sourceIndex) {
            list[sourceIndex].order_num =
                (list[destinationIndex].order_num +
                    list[destinationIndex - 1].order_num) /
                2;
            updateTagarray(sortList(list));
            sorttag([{ value: "custom" }]);
            return;
        }

        list[sourceIndex].order_num =
            (list[destinationIndex].order_num +
                list[destinationIndex + 1].order_num) /
            2;
        updateTagarray(sortList(list));
        sorttag([{ value: "custom" }]);
    };

    const TagDivUI = (property) => {
        let tagdata = property.data;
        let i = property.index;
        let bg = tagdata.custom_color || defaultColors[tagdata.class];
        return (
            <div className="col-2 tag_col_sec">
                <div className={`tag_cols`} style={{ background: `${bg}40` }}>
                    <h2 onClick={(e) => checkGroupcontact(e, tagdata)}>
                        {tagdata.name} ({tagdata.users === undefined ? 0 : tagdata.users})
                    </h2>
                    <div className="tag_icons">
                        <span
                            className={`action_icons ms-0 first`}
                            style={{ background: bg }}
                            onClick={(e) => checkGroupcontact(e, tagdata)}
                        >
                            <VisibilityIcon style={{ color: "#fff", fontSize: "22px" }} />
                        </span>
                        <span
                            className={`action_icons`}
                            style={{ background: bg }}
                            onClick={() => handleEditTag(tagdata, i)}
                        >
                            <EditIcon style={{ color: "#fff", fontSize: "22px" }} />
                        </span>
                        <span
                            className={`action_icons last`}
                            style={{ background: bg }}
                            onClick={(e) => deleteTag(e, tagdata.id, i)}
                        >
                            <DeleteIcon style={{ color: "#fff", fontSize: "22px" }} />
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const handleEditTag = (tagdata, i) => {
        setEditModalOpen(true);
        setEditModalData(tagdata);
    };

    const handleEditModalClose = () => {
        setEditModalOpen(false);
        setEditModalData({});
    };

    const handleOnChangeTagName = (e) => {
        let data = { ...editModalData };
        data.name = e.target.value;
        setEditModalData(data);
    };

    const handleOnChangeTagColor = (color) => {
        let data = { ...editModalData };
        data.custom_color = color;
        setEditModalData(data);
    };

    const handleUpdateBtnClicked = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        const data = {
            color: editModalData.custom_color,
            name: editModalData.name,
        };
        axios
            .post(UPDATE_TAG_COLOR_ND_NAME + "/" + editModalData.id, data, {
                headers: headers,
            })
            .then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    fecthdata();
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
                handleEditModalClose();
            })
            .catch((error) => {
                toast.error("Something went wrong, try again later", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                handleEditModalClose();
            });
    };

    return (
        <div className="col-md-10">
            <div className="row">
                <div className="col-md-9 right_side">
                    <ToastContainer />
                    <div className="row head_name">
                        <div className="col-md-2 left_col">
                            <h3>Tags</h3>
                        </div>
                        <div className="col-md-10 right_col align-items-center justify-content-center d-flex">
                            <div className="d-flex justify-content-end">
                                <div className="head_top upload">
                                    <ImportTag tagdata={exceltagdata} />
                                </div>
                                <div className="head_top upload">
                                    <div
                                        className="new_add_button-2"
                                        onClick={(e) => exportTag(e)}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M4.16683 9.3763C4.51201 9.3763 4.79183 9.65612 4.79183 10.0013L4.79183 15.0013C4.79183 15.1164 4.8851 15.2096 5.00016 15.2096L15.0002 15.2096C15.1152 15.2096 15.2085 15.1164 15.2085 15.0013L15.2085 10.0013C15.2085 9.65612 15.4883 9.3763 15.8335 9.3763C16.1787 9.3763 16.4585 9.65612 16.4585 10.0013L16.4585 15.0013C16.4585 15.8067 15.8056 16.4596 15.0002 16.4596L5.00016 16.4596C4.19475 16.4596 3.54183 15.8067 3.54183 15.0013L3.54183 10.0013C3.54183 9.65612 3.82165 9.3763 4.16683 9.3763Z"
                                                fill="#572F5B"
                                            />
                                            <path
                                                d="M9.07081 2.91797C8.61058 2.91797 8.23748 3.29106 8.23748 3.7513L8.23748 7.79796C7.94129 7.81707 7.64534 7.84099 7.34974 7.86972L7.0954 7.89444C6.68187 7.93463 6.43421 8.37376 6.61377 8.74844C7.34379 10.2717 8.34277 11.6507 9.56266 12.8191L9.58873 12.844C9.82112 13.0666 10.1876 13.0666 10.42 12.844L10.4461 12.8191C11.666 11.6507 12.6649 10.2717 13.395 8.74844C13.5745 8.37376 13.3269 7.93463 12.9133 7.89444L12.659 7.86972C12.3634 7.84099 12.0674 7.81707 11.7712 7.79796L11.7712 3.7513C11.7712 3.29107 11.3981 2.91797 10.9379 2.91797L9.07081 2.91797Z"
                                                fill="#572F5B"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div className="head_top search_field">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search tags"
                                            onChange={(e) => searchinput(e)}
                                        />
                                        <button className="tag_search_button">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="13"
                                                viewBox="0 0 14 13"
                                                fill="none"
                                            >
                                                <path
                                                    d="M12.8272 11.9041L10.4153 9.51166C11.3515 8.34433 11.8049 6.86266 11.6822 5.37132C11.5596 3.87997 10.8702 2.49231 9.75578 1.49365C8.6414 0.494996 7.18675 -0.0387395 5.69092 0.00219149C4.1951 0.0431225 2.77181 0.655609 1.71371 1.71371C0.655609 2.77181 0.0431225 4.1951 0.00219149 5.69092C-0.0387395 7.18675 0.494996 8.6414 1.49365 9.75578C2.49231 10.8702 3.87997 11.5596 5.37132 11.6822C6.86266 11.8049 8.34433 11.3515 9.51166 10.4153L11.9041 12.8077C11.9645 12.8686 12.0364 12.917 12.1156 12.95C12.1948 12.983 12.2798 13 12.3656 13C12.4514 13 12.5364 12.983 12.6156 12.95C12.6949 12.917 12.7668 12.8686 12.8272 12.8077C12.9444 12.6865 13.0099 12.5245 13.0099 12.3559C13.0099 12.1873 12.9444 12.0253 12.8272 11.9041ZM5.86455 10.4153C4.9645 10.4153 4.08466 10.1484 3.33629 9.64837C2.58792 9.14832 2.00464 8.43759 1.66021 7.60605C1.31577 6.77451 1.22565 5.85951 1.40124 4.97675C1.57684 4.09399 2.01025 3.28312 2.64669 2.64669C3.28312 2.01025 4.09399 1.57684 4.97675 1.40124C5.85951 1.22565 6.77451 1.31577 7.60605 1.66021C8.43759 2.00464 9.14832 2.58792 9.64837 3.33629C10.1484 4.08466 10.4153 4.9645 10.4153 5.86455C10.4153 7.07149 9.93585 8.22899 9.08242 9.08242C8.22899 9.93585 7.07149 10.4153 5.86455 10.4153Z"
                                                    fill="#181818"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="head_top">
                                    <div className="dropdown">
                                        <Select
                                            className="dropdown_tag"
                                            placeholder="Sort Tags"
                                            options={options}
                                            onChange={(values) => sorttag(values)}
                                        />
                                    </div>
                                </div>
                                <div className="actions action_last_col">
                                    <SaveTagComponent tagdata={newtagdata} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {status === true ? (
                        <Loader />
                    ) : (
                        <>
                            <div className="row tags_sec">
                                {tagarray.length === 0 ? (
                                    <h4 style={{ textAlign: "center" }}>No data found</h4>
                                ) : (
                                    <ListManager
                                        items={tagarray}
                                        direction="horizontal"
                                        className="list-manager-container"
                                        maxItems={maxItems}
                                        onDragEnd={handleOnDragEnd}
                                        render={(item, i) => <TagDivUI data={item} index={i} />}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>
                <div className="tag-contacts col-md-3">
                    {Object.keys(groupcontact).length > 0 && (
                        <ContactTag
                            tagdata={groupcontact}
                            tagcomponentarray={tagarray}
                            callupdateback={updatepopupback}
                        />
                    )}
                </div>
            </div>
            {editModalOpen && (
                <Modal
                    show={editModalOpen}
                    onHide={() => {
                        handleEditModalClose();
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Update Tag Info</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <Form.Label>Tag name</Form.Label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">
                                            <LocalOfferIcon />
                                        </InputGroup.Text>
                                        <Form.Control
                                            placeholder="Tag name"
                                            aria-label="Tag name"
                                            aria-describedby="basic-addon1"
                                            maxLength={50}
                                            value={editModalData.name}
                                            onChange={handleOnChangeTagName}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col md={12}>
                                    <Form.Label>Tag Color</Form.Label>
                                    <div>
                                        <HexColorPicker
                                            color={
                                                editModalData.custom_color ||
                                                defaultColors[editModalData.class]
                                            }
                                            onChange={handleOnChangeTagColor}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                handleEditModalClose();
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            className="add_tag button_global"
                            variant="primary"
                            onClick={() => {
                                handleUpdateBtnClicked();
                            }}
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
}
