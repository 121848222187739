import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ListManager } from 'react-beautiful-dnd-grid';
import {
    TEMPLATE_MESSAGE,
    UPDATE_TEMPLATE_MESSASGE,
    DELETE_TEMPLATE_MESSAGE,
    ADD_TEMPLATE_MESSAGE,
    UPDATE_MESSAGE_ORDER
} from '_helpers/api';
import Loader from '_components/loader';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Select from 'react-dropdown-select';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CreateIcon from '@mui/icons-material/Create';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

import { EditorState, convertToRaw } from 'draft-js';
export default function TemplateMessage(props) {
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);
    const [loader, setLoader] = useState(false);
    const [selectedinput, setSelectedinput] = useState('');
    const { user: authUser } = useSelector((x) => x.auth);
    const [template, setTemplate] = useState([]);
    const [newcopytemplatearray, setnewcopytemplatearray] = useState([]);
    const [selectedcheck, setSelectedcheck] = useState(0);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [divVisible, setDivVisible] = useState(true);
    const [chevronToggle, setChevronToggle] = useState(false);

    const toggleDivVisibility = () => {
        setDivVisible(!divVisible);
        setChevronToggle(!chevronToggle);
    };

    useEffect(() => {
        fecthdata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.templatedata.id]);

    const fecthdata = () => {
        setLoader(true);
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authUser.token}`,
        };
        axios
            .get(`${TEMPLATE_MESSAGE}/${props.templatedata.id}`, {
                headers: headers,
            })
            .then((response) => {
                setLoader(false);
                if (response.data.status === 200) {
                    setTemplate(response.data.template_message_data);
                    setnewcopytemplatearray(response.data.template_message_data);
                } else {
                    setTemplate([]);
                    setnewcopytemplatearray([]);
                }
            })
            .catch((error) => {
                setLoader(false);
            });
    };
    const showmessage = (e, id, name) => {
        e.preventDefault();
        setSelectedcheck(id);
        setSelectedinput(name);
    };
    const handleValuemessage = (e) => {
        setSelectedinput(e.target.value);
    };

    const messageupdate = (e, id, template_id, i) => {
        const indexToUpdate = template.findIndex(item => item.id === id);

        console.log(id, "index", indexToUpdate)
        template[indexToUpdate].message = selectedinput;
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authUser.token}`,
        };
        const data = {
            template_id: template_id,
            message: selectedinput,
        };
        axios
            .post(`${UPDATE_TEMPLATE_MESSASGE}/${id}`, data, {
                headers: headers,
            })
            .then((response) => {
                setLoader(false);
                if (response.data.status === 200) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((error) => { });
        setSelectedcheck(0);
        setSelectedinput(null);
    };

    const deleteMessage = (e, id, index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#572f5b',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                setTemplate((current) => current.filter((array) => array.id !== id));
                deleteApi(id);
                Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
            }
        });
    };

    const deleteApi = (id) => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authUser.token}`,
        };
        axios
            .get(DELETE_TEMPLATE_MESSAGE + '/' + id, {
                headers: headers,
            })
            .then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((error) => { });
    };

    const handleEditorChange = (state) => {
        setEditorState(state);
    };

    const savenewmessage = () => {
        if (
            (convertToRaw(editorState.getCurrentContent()).blocks.length === 1) &
            (convertToRaw(editorState.getCurrentContent()).blocks[0].text === '')
        ) {
            //
        } else {
            const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
            const value = blocks
                .map((block) => (!block.text.trim() && '\n') || block.text)
                .join('\n');

            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authUser.token}`,
            };
            const data = {
                template_id: props.templatedata.id,
                message: value,
            };
            axios
                .post(`${ADD_TEMPLATE_MESSAGE}`, data, {
                    headers: headers,
                })
                .then((response) => {
                    setLoader(false);
                    setEditorState(EditorState.createEmpty());
                    if (response.data.status === 200) {
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        fecthdata();
                    } else {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch((error) => { });
        }
    };

    const sorttemplate = (data) => {
        var tempdata = newcopytemplatearray;
        if (data[0].value === 'A-Z') {
            tempdata.sort((a, b) => a.name.localeCompare(b.name));
        } else if (data[0].value === 'Z-A') {
            tempdata.sort((a, b) => b.name.localeCompare(a.name));
        } else if (data[0].value === 'oldest-first-asc') {
            tempdata.sort(function (a, b) {
                return a.id - b.id;
            });
        } else if (data[0].value === 'newest-first-desc') {
            tempdata.sort(function (a, b) {
                return b.id - a.id;
            });
        } else {
            tempdata = newcopytemplatearray;
        }
        forceUpdate();
        setTemplate(tempdata);
    };

    const options = [
        {
            label: 'Oldest first',
            value: 'oldest-first-asc',
        },
        {
            label: 'Newest first',
            value: 'newest-first-desc',
        },
    ];

    const searchinput = (data) => {
        var input_value = data.target.value;
        if (input_value === '') {
            setTemplate(newcopytemplatearray);
            forceUpdate();
        } else {
            var tempdata = newcopytemplatearray;
            const filteredArray = tempdata.filter((item) =>
                item.message.toLowerCase().includes(input_value.toLowerCase())
            );
            if (filteredArray.length === 0) {
                setTemplate([]);
            } else {
                setTemplate(filteredArray);
            }
            forceUpdate();
        }
    };

    const sorttag = (data) => {
        var tempdata = template;
        if (data[0].value === 'A-Z') {
            tempdata.sort((a, b) => a.name.localeCompare(b.name));
        } else if (data[0].value === 'Z-A') {
            tempdata.sort((a, b) => b.name.localeCompare(a.name));
        } else if (data[0].value === 'oldest-first-asc') {
            tempdata.sort((a, b) => a.id - b.id);
        } else if (data[0].value === 'newest-first-desc') {
            tempdata.sort((a, b) => b.id - a.id);
        } else if (data[0].value === 'custom') {
            tempdata.sort((a, b) => a.order_num - b.order_num);
        } else {
            tempdata = template;
        }
        setTemplate(tempdata);
        forceUpdate();
    };


    function sortList(list) {
        let newList = list
            .slice()
            .sort((first, second) => first.order_num - second.order_num);
        let newTagsData = newList.reduce((previousValue, currentValue) => {
            let temp = {
                message_id: currentValue.id,
                order_num: previousValue.length + 1,
            };
            previousValue.push(temp);
            return previousValue;
        }, []);
        updateSortSettingInDB(newTagsData);
        return newList;
    }

    const updateSortSettingInDB = (sortedData) => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        axios
            .post(UPDATE_MESSAGE_ORDER, { changedorder: sortedData }, {
                headers: headers,
            }).then((response) => { }).catch((error) => { });
    }

    const handleOnDragEnd = (sourceIndex, destinationIndex) => {
        if (destinationIndex === sourceIndex) {
            return;
        }
        let list = [...template];

        if (destinationIndex === 0) {
            list[sourceIndex].order_num = list[0].order_num - 1;
            setTemplate(sortList(list));
            sorttag([{ value: 'custom' }]);
            return;
        }

        if (destinationIndex === list.length - 1) {
            list[sourceIndex].order_num = list[list.length - 1].order_num + 1;
            setTemplate(sortList(list));
            sorttag([{ value: 'custom' }]);
            return;
        }

        if (destinationIndex < sourceIndex) {
            list[sourceIndex].order_num =
                (list[destinationIndex].order_num +
                    list[destinationIndex - 1].order_num) /
                2;
            setTemplate(sortList(list));
            sorttag([{ value: 'custom' }]);
            return;
        }

        list[sourceIndex].order_num =
            (list[destinationIndex].order_num +
                list[destinationIndex + 1].order_num) /
            2;
        setTemplate(sortList(list));
        sorttag([{ value: 'custom' }]);
    };

    return (
        <div className="col-md-7">
            <ToastContainer />
            <div className="row head_name">
                <div className="col-md-12 right_col p-0">
                    <div className="row">
                        <div className="col-md-6 head_top d-flex align-items-center">
                            <h4>
                                {' '}
                                {props.templatedata.name} (
                                {props.templatedata.countmessage_count})
                            </h4>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex align-items-end justify-content-end">
                                <div className="head_top search_field ">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={(e) => searchinput(e)}
                                            placeholder="Search"
                                        />
                                        <span className="input-group-text">
                                            <SearchIcon style={{fill:"#572f5b"}}/>
                                        </span>
                                    </div>
                                </div>
                                <div className="head_top actions action_last_col">
                                    <div className="dropdown">
                                        <Select
                                            className="dropdown_tag_new"
                                            options={options}
                                            onChange={(values) => sorttemplate(values)}
                                        />
                                    </div>
                                </div>
                                <div className="template_cols"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loader === true ? (
                <Loader />
            ) : (
                <>
                    <>
                        <div
                            className="col-11"
                            style={{
                                border: '2px solid #d9d6da',
                                minHeight: '230px',
                                background: '#f9f2fa',
                                marginBottom: '30px',
                                borderRadius: '13px',
                                position: 'relative',

                            }}
                        >
                            <div className="row">
                                <div className="col-10">
                                    <Editor
                                        editorState={editorState}
                                        onEditorStateChange={handleEditorChange}
                                        wrapperClassName="wrapper-class"
                                        editorClassName="editor-class"
                                        toolbarClassName="toolbar-class"
                                        toolbar={{
                                            options: ['history', 'inline', 'blockType'], // Include 'history' option
                                            inline: {
                                                options: [
                                                    'bold',
                                                    'italic',
                                                    'strikethrough',
                                                    'monospace',
                                                ],
                                            },
                                        }}
                                    />
                                </div>
                                <div className="col-2 left_border_column"  >
                                    <img
                                        src={
                                            chevronToggle
                                                ? 'assets/images/chevup.png'
                                                : 'assets/images/chevdown.png'
                                        }
                                        style={{ paddingLeft: '1.5rem', paddingTop: '.1rem' }}
                                        alt=""
                                        onClick={toggleDivVisibility}
                                    />
                                    <div className="new_add_button" onClick={savenewmessage} style={{ display: divVisible ? 'block' : 'none', }}>
                                        <SendOutlinedIcon style={{background: "rgb(175 94 157)", fill: "rgb(255, 255, 255)", padding: "7px", height: "36px", width: "36px", marginRight: '20px', borderRadius: "5px"}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    {template.length === 0 ? (
                        <h4 style={{ textAlign: 'center', marginTop: '15px' }}>
                            No Message found
                        </h4>
                    ) : (<div className="scroll-area">
                        <ListManager
                            items={template}
                            direction="vertical"
                            maxItems={1}
                            onDragEnd={handleOnDragEnd}
                            render={(item, i) => (
                                <>
                                    <div className="row template_message" key={i}>
                                        <div className="col-11">
                                            {selectedcheck === item.id ? (
                                                <textarea
                                                    className="form-control"
                                                    onChange={handleValuemessage}
                                                    name="text"
                                                    disabled={
                                                        selectedcheck === item.id ? false : true
                                                    }
                                                >
                                                    {item.message}
                                                </textarea>
                                            ) : (
                                                <p className="form-control" style={{ minHeight: '55px' }}>
                                                    {item.message}
                                                </p>
                                            )}
                                        </div>
                                        <div className="col-1 d-flex align-items-center p-0">
                                            {selectedcheck === item.id ? (
                                                <span
                                                    style={{ width: "30px", display: "flex", justifyContent: "center", alignItems: "center", height: "50px" }}
                                                    onClick={(e) =>
                                                        messageupdate(
                                                            e,
                                                            item.id,
                                                            item.template_id,
                                                            i
                                                        )
                                                    }
                                                >
                                                    <CheckIcon style={{fill:"#572f5b"}} />
                                                </span>
                                            ) : (
                                                <span
                                                    style={{ width: "30px", display: "flex", justifyContent: "center", alignItems: "center", height: "50px" }}
                                                    onClick={(e) =>
                                                        showmessage(e, item.id, item.message)
                                                    }
                                                >
                                                    <CreateIcon style={{fill:"#572f5b"}} />
                                                </span>
                                            )}
                                            <span
                                                style={{ width: "30px", display: "flex", justifyContent: "center", alignItems: "center", height: "50px" }}
                                                onClick={(e) => deleteMessage(e, item.id, i)}
                                            >
                                                <DeleteIcon style={{fill:"#572f5b"}} />
                                            </span>
                                        </div>
                                    </div>
                                </>
                            )}
                        />
                    </div>
                    )}
                </>
            )}
        </div>
    );
}